import {AxiosError} from 'axios'
import {useQuery, UseQueryOptions} from 'react-query'
import {useNavigate} from 'react-router-dom'

import {handleError} from '../../../shared/helpers/errorHandling'
import {useAxios} from '../../../shared/hooks/useApi'
import {ChecklistTemplateDto} from '../../types'

const QueryKey = 'checklist-templates'

export const useChecklistTemplates = ({
  params,
  options
}: {
  params?: {templateTypes?: string[]}
  options?: UseQueryOptions<ChecklistTemplateDto[], AxiosError>
} = {}) => {
  const axiosInstance = useAxios()
  const navigate = useNavigate()

  return useQuery<ChecklistTemplateDto[], AxiosError>(
    [QueryKey, params],
    async () => {
      const response = await axiosInstance.get<ChecklistTemplateDto[]>(
        '/hroc-checklists/api/checklist-templates',
        {
          params
        }
      )

      return response.data
    },
    {
      staleTime: 1000 * 60 * 15,
      onError: (err) => handleError(err, navigate),
      ...options
    }
  )
}
