import {Iso8601} from '@hconnect/uikit'
import {AxiosError, AxiosInstance} from 'axios'
import {useQuery, UseQueryOptions} from 'react-query'
import {useNavigate} from 'react-router-dom'

import {handleError} from '../../../shared/helpers/errorHandling'
import {useAxios} from '../../../shared/hooks/useApi'
import {
  KilnHeatConsumptionsResponse,
  QuarterlyForecastKilnHeatConsumptionsHierarchy,
  TimeRange,
  TimeRangeWithOffset
} from '../../types'

export const getKilnHeatConsumptionQueryKey = (showYTM?: boolean) =>
  showYTM ? 'kiln-heat-consumptions-ytm' : 'kiln-heat-consumptions-mtm'

export const getKilnHeatConsumption = async (
  axiosInstance: AxiosInstance,
  {timeRange, locationId, showYTM}: TimeRangeWithOffset
) => {
  const QueryKey = getKilnHeatConsumptionQueryKey(showYTM)
  const payload: TimeRange = {startDate: timeRange.startDate, endDate: timeRange.endDate}

  const url = `/kpi/dashboard/${QueryKey}`
  const response = await axiosInstance.post<KilnHeatConsumptionsResponse>(url, payload, {
    params: {locationId}
  })

  return response.data
}

export const useKilnHeatConsumption = <T>(
  {timeRange, locationId, showYTM}: TimeRangeWithOffset,
  options?: UseQueryOptions<KilnHeatConsumptionsResponse, AxiosError, T>
) => {
  const axiosInstance = useAxios()
  const navigate = useNavigate()
  const QueryKey = getKilnHeatConsumptionQueryKey(showYTM)

  return useQuery<KilnHeatConsumptionsResponse, AxiosError, T>(
    [QueryKey, timeRange.startDate, timeRange.endDate, locationId],
    async () => {
      return await getKilnHeatConsumption(axiosInstance, {timeRange, locationId, showYTM})
    },
    {
      onError: (err) => handleError(err, navigate),
      ...options
    }
  )
}

export const useKilnHeatConsumptionQuarterly = (
  timestamp: Iso8601,
  locationId?: string,
  options?: UseQueryOptions<QuarterlyForecastKilnHeatConsumptionsHierarchy | null, AxiosError>
) => {
  const axiosInstance = useAxios()
  const navigate = useNavigate()

  return useQuery<QuarterlyForecastKilnHeatConsumptionsHierarchy | null, AxiosError>(
    ['kiln-heat-consumptions-quarterly', timestamp, locationId],
    async () => {
      if (!locationId) return Promise.resolve(null)

      const response = await axiosInstance.get<QuarterlyForecastKilnHeatConsumptionsHierarchy>(
        '/kpi/dashboard/kiln-heat-consumptions-qf',
        {
          params: {locationId, timestamp}
        }
      )
      return response.data
    },
    {
      onError: (err) => handleError(err, navigate),
      ...options
    }
  )
}
