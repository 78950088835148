import {ExpandMoreOutlined as ExpandIcon} from '@mui/icons-material'
import {Box, Divider, Typography} from '@mui/material'
import React, {FC, ReactNode} from 'react'

type ExpandableListProps = {
  label: string | ReactNode
  content: ReactNode
  isOpen: boolean
  id: string
  toggleState: (id: string) => void
  keepMounted?: boolean
  action?: ReactNode
  'data-test-id'?: string
  showDivider?: boolean
}

export const ExpandableListItem: FC<ExpandableListProps> = ({
  'data-test-id': dataTestId,
  id,
  label,
  showDivider,
  content,
  isOpen,
  toggleState,
  action,
  keepMounted
}) => {
  return (
    <Box data-test-id={dataTestId} data-state={isOpen ? 'open' : 'close'}>
      <Box
        display="flex"
        justifyContent="space-between"
        onClick={() => toggleState(id)}
        sx={[
          (theme) => ({
            display: 'flex',
            alignItems: 'center',
            minHeight: theme.spacing(7),
            padding: theme.spacing(0, 2, 0, 2),
            '&:hover': {
              backgroundColor: '#f7fcff'
            },
            cursor: 'pointer'
          }),
          {
            px: 1
          }
        ]}
      >
        <Typography
          sx={{
            display: 'flex',
            alignItems: 'center'
          }}
          color="textPrimary"
          variant="body1"
          component={Box}
          data-test-id={dataTestId && `${dataTestId}-label`}
        >
          {content ? (
            <ExpandIcon
              sx={[
                {
                  transition: 'transform 0.5s ease',
                  transform: 'rotate(-90deg)'
                },
                isOpen && {
                  transform: 'rotate(0deg)'
                }
              ]}
            />
          ) : (
            <Box component="span" width={(theme) => theme.spacing(2.5)} />
          )}
          {label}
        </Typography>
        {action}
      </Box>

      {showDivider && <Divider color="light" />}

      {keepMounted ? (
        <Box sx={!isOpen ? {display: 'none'} : undefined}>{content}</Box>
      ) : (
        isOpen && content
      )}
    </Box>
  )
}
