import {Typography, SxProps, Theme, useTheme} from '@mui/material'
import {SystemStyleObject} from '@mui/system/styleFunctionSx/styleFunctionSx'
import React, {FC, ReactNode} from 'react'

import {mergeSx} from '../../utils'
import {CardBox} from '../containers'

type ScoreItemProps = {
  header: string | number | ReactNode
  label: string | ReactNode
  borderLess?: boolean
  headerSx?: SystemStyleObject<Theme>
  sx?: SxProps<Theme>
  'data-test-id'?: string
  onClick?: () => void
  active?: boolean
}

export const ScoreItem: FC<ScoreItemProps> = ({
  sx,
  borderLess,
  header,
  label,
  headerSx,
  onClick,
  active,
  'data-test-id': dataTestId
}) => {
  const {shadows} = useTheme()

  return (
    <CardBox
      sx={mergeSx(
        {
          p: {xs: 2, md: 2},
          gap: 0.5,
          minWidth: 86,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          boxShadow: shadows[3],
          cursor: 'pointer',
          ...(active && {backgroundColor: 'grey.800'}),
          ...(borderLess ? {p: {xs: 0, md: 0}, boxShadow: 'none'} : {})
        },
        sx
      )}
      onClick={onClick}
      data-test-id={dataTestId}
    >
      <Typography
        variant="h1"
        sx={mergeSx(headerSx || {}, {fontWeight: 300, ...(active && {color: 'common.white'})})}
      >
        {header}
      </Typography>
      <Typography
        sx={[!!active && {color: 'common.white'}]}
        display="inline-flex"
        variant="caption"
      >
        {label}
      </Typography>
    </CardBox>
  )
}
