import {Iso8601} from '@hconnect/uikit'
import {AxiosError, AxiosInstance} from 'axios'
import {useQuery, UseQueryOptions} from 'react-query'
import {useNavigate} from 'react-router-dom'

import {handleError} from '../../../shared/helpers/errorHandling'
import {useAxios} from '../../../shared/hooks/useApi'
import {
  KilnCoefficientsResponse,
  QuarterlyForecastKilnCoefficientHierarchy,
  TimeRange,
  TimeRangeWithOffset
} from '../../types'

export const getKilnCoefficientQueryKey = (showYTM?: boolean) =>
  showYTM ? 'kiln-coefficients-ytm' : 'kiln-coefficients-mtm'

export const getKilnCoefficients = async (
  axiosInstance: AxiosInstance,
  {timeRange, locationId, showYTM}: TimeRangeWithOffset
) => {
  const payload: TimeRange = {startDate: timeRange.startDate, endDate: timeRange.endDate}
  const url = `/kpi/dashboard/${getKilnCoefficientQueryKey(showYTM)}`
  const response = await axiosInstance.post<KilnCoefficientsResponse>(url, payload, {
    params: {locationId}
  })

  return response.data
}

export const useKilnCoefficients = <T>(
  {timeRange, locationId, showYTM}: TimeRangeWithOffset,
  options?: UseQueryOptions<KilnCoefficientsResponse, AxiosError, T>
) => {
  const axiosInstance = useAxios()
  const navigate = useNavigate()
  const QueryKey = getKilnCoefficientQueryKey(showYTM)

  return useQuery<KilnCoefficientsResponse, AxiosError, T>(
    [QueryKey, timeRange.startDate, timeRange.endDate, locationId],
    async () => {
      return getKilnCoefficients(axiosInstance, {timeRange, locationId, showYTM})
    },
    {
      onError: (err) => handleError(err, navigate),
      ...options
    }
  )
}

// used in future when data is available HCP-68646
export const useKilnCoefficientsQuarterly = (
  timestamp: Iso8601,
  locationId?: string,
  options?: UseQueryOptions<QuarterlyForecastKilnCoefficientHierarchy | null, AxiosError>
) => {
  const axiosInstance = useAxios()
  const navigate = useNavigate()

  return useQuery<QuarterlyForecastKilnCoefficientHierarchy | null, AxiosError>(
    ['kiln-coefficients-quarterly', timestamp, locationId],
    async () => {
      if (!locationId) return Promise.resolve(null)

      const response = await axiosInstance.get<QuarterlyForecastKilnCoefficientHierarchy>(
        '/kpi/dashboard/kiln-coefficients-qf',
        {
          params: {locationId, timestamp}
        }
      )
      return response.data
    },
    {
      onError: (err) => handleError(err, navigate),
      ...options
    }
  )
}
