import plants from '@hconnect/common/hproduce/plants.json'
import {StoppageKind} from '@hconnect/common/types'
import {customThemeConstants, PageContainer, PageTitle} from '@hconnect/uikit/src/lib2'
import {Grid, Stack} from '@mui/material'
import moment from 'moment-timezone'
import {useEffect, useMemo, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {useQueryClient} from 'react-query'
import {generatePath, useNavigate, useParams} from 'react-router'

import {useAxios} from '../../shared/hooks/useApi'
import {StoppagesFailureChart, TopIssues} from '../components/downtime'
import {StoppageList} from '../components/StoppageList'
import {NewStoppagesBannerContainer} from '../containers'
import {getTimeStep} from '../helpers'
import {
  BaseAnalyticsEventName,
  useTrackDowntimeAnalyticsEvent
} from '../helpers/trackAnalyticsEvents'
import {useRcfaAvailability} from '../hooks/useRcfaAvailability'
import {
  RunningTimesOffPeriodsQueryKey,
  fetchRunningTimesOffPeriodsWithoutDetail,
  useRunningTimesOffPeriodsWithoutDetailCount
} from '../hooks/useRunningTimesOffPeriodsWithoutDetail'
import {useSortedStoppages} from '../hooks/useSortedStoppages'
import {useStoppageCodeStats} from '../hooks/useStoppageCodeStats'
import {useTimeRangeParam} from '../hooks/useTimeRangeParam'
import {getSystemTimezone} from '../hooks/useTimezone'
import {useTranslationPrefix} from '../hooks/useTranslationPrefix'
import {PLANT_DOWNTIME_STOPPAGES} from '../routing'
import {IssueGroupingKey} from '../types'

type PathParameter = {
  plantId: string
}

export const DownTimeDashboard = () => {
  const [isLoading, setIsLoading] = useState(true)

  const {t} = useTranslation()
  const {performancePrefix} = useTranslationPrefix()
  const {plantId} = useParams<PathParameter>()
  if (!plantId) throw new Error('Missing plantId prop')

  const trackAnalyticsEvent = useTrackDowntimeAnalyticsEvent()
  const timezone = getSystemTimezone()
  const startOfToday = moment.utc(new Date()).tz(timezone).startOf('day')
  const timeRange = {
    from: startOfToday.clone().subtract(12, 'month').startOf('month').toDate(),
    to: startOfToday.clone().endOf('day').toDate()
  }

  const [, setTimeRange] = useTimeRangeParam()
  const navigate = useNavigate()
  const localTimeRange = `[${timeRange.from.toJSON()},${timeRange.to.toJSON()}]`

  useEffect(() => {
    localTimeRange && setTimeRange(localTimeRange)
    setIsLoading(false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [localTimeRange])

  const rcfaAvailabilityData = useRcfaAvailability(plantId, {
    startDate: timeRange.from.toJSON(),
    endDate: timeRange.to.toJSON()
  })
  const timeStep = getTimeStep(timeRange)

  const plantName = plants[plantId]?.name || plantId
  const path = useMemo(() => generatePath(PLANT_DOWNTIME_STOPPAGES, {plantId}), [plantId])
  const axiosInstance = useAxios()

  const {
    topIssuesSortingKey,
    setTopIssuesSortingKey,
    topIssuesGroupingKey,
    setTopIssuesGroupingKey,
    sortedStoppages,
    aggregatedStoppagesQuery
  } = useSortedStoppages({
    plantId,
    timeRange,
    selectedStoppages: [StoppageKind.Incident, StoppageKind.Circumstance],
    timeStep
  })
  const {data: stoppageCodeStats = {}} = useStoppageCodeStats(
    plantId,
    {
      ...timeRange,
      groupBy: topIssuesGroupingKey
    },
    {enabled: topIssuesGroupingKey !== IssueGroupingKey.STOPPAGE_CODE}
  )
  const queryClient = useQueryClient()

  const stoppagesWithoutDetailTimeRange = {
    from: startOfToday.clone().subtract(3, 'month').toDate(),
    to: startOfToday.clone().endOf('day').toDate()
  }
  const {data: newStoppagesCount, isLoading: stoppagesCountIsLoading} =
    useRunningTimesOffPeriodsWithoutDetailCount(
      plantId,
      {
        from: stoppagesWithoutDetailTimeRange.from.toJSON(),
        to: stoppagesWithoutDetailTimeRange.to.toJSON()
      },
      {
        onSuccess: (data) => {
          if (data > 0) {
            // this query is fetched with useRunningTimesOffPeriodsWithoutDetail
            void queryClient.prefetchQuery(
              [
                RunningTimesOffPeriodsQueryKey,
                plantId,
                stoppagesWithoutDetailTimeRange.from.toJSON(),
                stoppagesWithoutDetailTimeRange.to.toJSON()
              ],
              () =>
                fetchRunningTimesOffPeriodsWithoutDetail({
                  plantId,
                  axiosInstance,
                  params: {
                    from: stoppagesWithoutDetailTimeRange.from.toJSON(),
                    to: stoppagesWithoutDetailTimeRange.to.toJSON()
                  }
                })
            )
          }
        }
      }
    )
  const hasNewStoppages = !!newStoppagesCount

  if (isLoading) return null

  return (
    <PageContainer>
      <Stack
        pl={customThemeConstants().defaultGridSpacing}
        pb={customThemeConstants().defaultGridSpacing}
        gap={2}
        justifyContent={'space-between'}
      >
        <PageTitle ml={0} mb={0}>
          {t(`${performancePrefix}.downtime.label.header`)}
        </PageTitle>
        {plantName}
      </Stack>

      <Grid container spacing={2}>
        <Grid item sm={hasNewStoppages ? 8 : 12} xs={12}>
          <Grid container item spacing={2}>
            <Grid container item>
              <Grid item xs={12}>
                <TopIssues
                  plantId={plantId}
                  timeRange={timeRange}
                  title={t(`${performancePrefix}.downtime.label.topIssues`)}
                  issueCount={
                    topIssuesGroupingKey === IssueGroupingKey.TIME_INTERVAL ? undefined : 5
                  }
                  onClick={() => {
                    trackAnalyticsEvent(BaseAnalyticsEventName.UserClicksOn1LevelStoppagesGraph, {})
                    navigate(path)
                  }}
                  topIssuesSortingKey={topIssuesSortingKey}
                  setTopIssuesSortingKey={setTopIssuesSortingKey}
                  topIssuesGroupingKey={topIssuesGroupingKey}
                  setTopIssuesGroupingKey={setTopIssuesGroupingKey}
                  sortedStoppages={sortedStoppages}
                  aggregatedStoppagesQuery={aggregatedStoppagesQuery}
                  stoppageCodeStats={stoppageCodeStats}
                />
              </Grid>
            </Grid>
            <Grid container item spacing={2}>
              <Grid item xs={12} sm={6}>
                {rcfaAvailabilityData.data && (
                  <StoppageList plantId={plantId} rcfaData={rcfaAvailabilityData.data} />
                )}
              </Grid>
              <Grid item xs={12} sm={6}>
                <StoppagesFailureChart plantId={plantId} from={timeRange.from} to={timeRange.to} />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        {!stoppagesCountIsLoading && hasNewStoppages && (
          <Grid item xs={12} sm={4}>
            <NewStoppagesBannerContainer
              plantId={plantId}
              newStoppagesCount={newStoppagesCount ?? 0}
            />
          </Grid>
        )}
      </Grid>
    </PageContainer>
  )
}
