import type {TimeZone} from '@hconnect/uikit'

import {useConfig} from './configService'

export const getSystemTimezone = (): TimeZone => {
  return Intl.DateTimeFormat().resolvedOptions().timeZone
}

export const usePlantTimezone = ({plantId}: {plantId: string}): TimeZone => {
  const {data: config} = useConfig(plantId)
  return config?.timezone || getSystemTimezone()
}
