import {ContentBox} from '@hconnect/common/components/ContentBox'
import {EventsType, SideCardModes} from '@hconnect/common/components/eventsList/types'
import {SIDE_CARD_MIN_HEIGHT_IN_PX} from '@hconnect/common/consts'
import React from 'react'

import {HProduceTask} from '../types/task.types'

import {TaskDetails} from './TaskDetails'

type Props = SideCardModes & {
  setMode: (next: SideCardModes) => void
  doClose: (original?: EventsType, next?: EventsType) => void
  freeSearchText?: string
  timezone: string
  task?: HProduceTask
  translationPrefix?: string
}

export const SideCard: React.FC<Props> = ({task, ...props}) => {
  // TODO: setup permission
  // const allowedToViewDocuments = usePermission('VIEW_DOCUMENTS')

  if (task) {
    switch (props.mode) {
      case 'detailsMode':
        return (
          <TaskDetails
            data-test-id="page-events-details"
            doClose={props.doClose}
            item={task}
            key={'detailsMode-' + props.itemId}
            freeSearchText={props.freeSearchText}
            timezone={props.timezone}
            translationPrefix={props.translationPrefix}
          />
        )

      case 'editMode':
        return <>add edit mode</>
    }
  }

  return (
    <ContentBox
      data-test-id="sideCard-spinner"
      isLoading
      minHeight={SIDE_CARD_MIN_HEIGHT_IN_PX}
      mode="max100PercentOfParentHeight"
    />
  )
}
