import {AxiosError, AxiosInstance} from 'axios'
import {useCallback, useMemo} from 'react'
import {useQueries, UseQueryResult} from 'react-query'
import {useNavigate} from 'react-router-dom'

import {handleError} from '../../../shared/helpers/errorHandling'
import {useAxios} from '../../../shared/hooks/useApi'
import {mapMeanTimeBetweenFailuresDto} from '../../mappers'
import {
  KpiDataPayload,
  KpiPfyPayload,
  MeanTimeBetweenFailuresResponse,
  PlannedYearlyMeanTimeBetweenFailuresHierarchy
} from '../../types'

import {KpiValue} from './commonKpiApiTypes'
import {
  getMeanTimeBetweenFailures,
  getMeanTimeBetweenFailuresQueryKey
} from './useMeanTimeBetweenFailures'

export const getMeanTimeBetweenFailuresPfy = async (
  axiosInstance: AxiosInstance,
  payload?: KpiPfyPayload
) => {
  const url = '/kpi/dashboard/mean-time-between-failures-pfy'
  const response = await axiosInstance.get<PlannedYearlyMeanTimeBetweenFailuresHierarchy>(url, {
    params: {...payload}
  })

  return response.data
}

export const useMeanTimeBetweenFailuresData = (
  payload: KpiDataPayload,
  enabled = true
): KpiValue => {
  const axiosInstance = useAxios()
  const navigate = useNavigate()

  const QueryKey = getMeanTimeBetweenFailuresQueryKey(payload.showYTM)
  const queries = useQueries<
    [
      UseQueryResult<PlannedYearlyMeanTimeBetweenFailuresHierarchy, AxiosError>,
      UseQueryResult<MeanTimeBetweenFailuresResponse, AxiosError>
    ]
  >([
    {
      queryKey: [
        'mean-time-between-failures-pfy',
        {locationId: payload?.locationId, year: payload?.year}
      ],
      queryFn: () =>
        getMeanTimeBetweenFailuresPfy(axiosInstance, {
          locationId: payload?.locationId,
          year: payload?.year
        }),
      enabled,
      onError: (err: AxiosError) => handleError(err, navigate)
    },
    {
      queryKey: [
        QueryKey,
        {
          locationId: payload?.locationId,
          timeRange: payload.timeRange,
          showYTM: payload.showYTM
        }
      ],
      queryFn: () =>
        getMeanTimeBetweenFailures(axiosInstance, {
          locationId: payload?.locationId,
          timeRange: payload.timeRange,
          showYTM: payload.showYTM
        }),
      enabled,
      onError: (err: AxiosError) => handleError(err, navigate)
    }
  ])

  const refetch = useCallback(() => {
    queries.forEach(({error, refetch: queryRefetch}) => {
      if (error) {
        void queryRefetch()
      }
    })
  }, [queries])

  const [pfyQuery, dataQuery] = queries

  const meanTimeBetweenFailures = useMemo(() => {
    const pfyData = pfyQuery.data
    const data = dataQuery.data
    return pfyData && data ? mapMeanTimeBetweenFailuresDto(data, pfyData) : undefined
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(pfyQuery.data), JSON.stringify(dataQuery.data)])

  return {
    data: meanTimeBetweenFailures,
    isLoading: pfyQuery.isLoading || dataQuery.isLoading,
    error: pfyQuery.error || dataQuery.error,
    refetch
  }
}
