import {
  generateDueDateColumn,
  generateStatusColumn,
  generateTitleColumn
} from '@hconnect/common/components/eventsList/tableHelpers'
import {ViewMode} from '@hconnect/common/components/eventsList/types'
import {useEndOfPageScrollDebounce} from '@hconnect/common/hooks/useListScrollDebounce'
import {getTranslationKey} from '@hconnect/common/utils/translation.utils'
import {PaginationOptions} from '@hconnect/uikit'
import {Theme, useMediaQuery} from '@mui/material'
import React, {useEffect, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {generatePath, useNavigate} from 'react-router'

import {EventsTableWrapper} from '../components/EventsTableWrapper'
import {
  combineSearchParamsToQueryString,
  useMyTasks,
  useQueryParameterForViewMode,
  viewModeToUrlParams
} from '../hooks'
import {MY_TASKS} from '../routing'

const generateColumnsMap = (t: (key: string) => string, timezone: string) => ({
  XS: [generateTitleColumn(t)],
  S: [generateTitleColumn(t), generateStatusColumn(t, 'small')],
  M: [generateTitleColumn(t), generateStatusColumn(t, 'small')],
  L: [generateTitleColumn(t), generateDueDateColumn(t, timezone), generateStatusColumn(t, 'small')],
  XL: [
    generateTitleColumn(t),
    generateDueDateColumn(t, timezone),
    generateStatusColumn(t, 'regular')
  ]
})

const createUrl = (nextViewMode: ViewMode): string => {
  const viewModeParams = viewModeToUrlParams(nextViewMode)
  const queryString = combineSearchParamsToQueryString(viewModeParams)
  return `${generatePath(MY_TASKS)}?${queryString}`
}

type Props = {
  translationPrefix: string
}

const DEFAULT_PAGE_SIZE = 50

export const EventsTableLayout: React.FC<Props> = ({translationPrefix}) => {
  const {t} = useTranslation()
  const navigate = useNavigate()
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'))

  const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone

  // todo: use freeSearchText with filter in future
  const columnsMap = generateColumnsMap(
    (key: string) => t(getTranslationKey(key, translationPrefix)),
    timezone
  )

  const [pageNumber, changePage] = useState<number>(0)
  const [itemsPerPage, setItemsPerPage] = useState<number>(DEFAULT_PAGE_SIZE)
  const {data, isLoading} = useMyTasks(itemsPerPage, pageNumber, {
    keepPreviousData: isMobile
  })

  const shouldLoadNextPage = itemsPerPage < (data?.totalItemCount || 0)

  const {trackScrolling} = useEndOfPageScrollDebounce(() => {
    setItemsPerPage((items) => items + DEFAULT_PAGE_SIZE)
  }, shouldLoadNextPage)

  useEffect(() => {
    if (isMobile) {
      document.addEventListener('scroll', trackScrolling)
    }

    return () => document.removeEventListener('scroll', trackScrolling)
  }, [isMobile, trackScrolling])

  useEffect(() => {
    if (isMobile) {
      setItemsPerPage(DEFAULT_PAGE_SIZE)
      changePage(0)
    }
  }, [isMobile])

  const onPaginationChange = (pageNumber: number, itemsPerPage: number) => {
    changePage(pageNumber)
    setItemsPerPage(itemsPerPage)
  }

  const paginationOptions: PaginationOptions = {
    page: pageNumber,
    rowsPerPage: itemsPerPage,
    onPaginationChange: onPaginationChange,
    totalDataLength: data?.totalItemCount ?? 0
  }

  const viewMode = useQueryParameterForViewMode()
  const setViewMode = (nextViewMode: ViewMode) => {
    navigate(createUrl(nextViewMode))
  }

  return (
    <EventsTableWrapper
      events={data?.items || []}
      columnsMap={columnsMap}
      isLoading={isLoading}
      viewMode={viewMode}
      setViewMode={setViewMode}
      paginationOptions={isMobile ? undefined : paginationOptions}
      timezone={timezone}
      translationPrefix={translationPrefix}
      showBottomProgress={isMobile && shouldLoadNextPage}
    />
  )
}
