import {Iso8601} from '@hconnect/uikit'
import {AxiosError} from 'axios'
import {useQuery, UseQueryOptions} from 'react-query'
import {useNavigate} from 'react-router-dom'

import {handleError} from '../../../shared/helpers/errorHandling'
import {useAxios} from '../../../shared/hooks/useApi'
import {QuarterlyForecastClinkerProductionHierarchy} from '../../types'

export const useClinkerProductionQuarterly = (
  timestamp: Iso8601,
  locationId?: string,
  options?: UseQueryOptions<QuarterlyForecastClinkerProductionHierarchy | null, AxiosError>
) => {
  const axiosInstance = useAxios()
  const navigate = useNavigate()

  return useQuery<QuarterlyForecastClinkerProductionHierarchy | null, AxiosError>(
    ['clinker-production-quarterly', timestamp, locationId],
    async () => {
      if (!locationId) return Promise.resolve(null)

      const response = await axiosInstance.get<QuarterlyForecastClinkerProductionHierarchy>(
        '/kpi/dashboard/clinker-production-qf',
        {
          params: {locationId, timestamp}
        }
      )
      return response.data
    },
    {
      onError: (err) => handleError(err, navigate),
      ...options
    }
  )
}
