import {PlantEquipmentType, RunningTimeType} from '@hconnect/common/types'

import {LocationType} from './location.types'
import {PlannedYearlyProductionVolumeResponse} from './performance.types'
import {EquipmentRunningTimesResult} from './runningTimes.types'

export type NoDataReason = 'noData' | 'grindingUnitOnly' | 'noPxTrend'

export type PxTrendMetaData = {
  isGrindingUnit: boolean
  productionVolumesAvailable: boolean
  pxTrendAvailable: boolean
  runinngTimesAvailable: boolean
  siloFillLevelsAvailable: boolean
}

export type Plant = {
  plantId: string
  name: string
  lastUpdate?: string
  parentLocationId?: string
  parentLocationType: LocationType
  timezone?: string
  countryCode: string
  countryName: string
  pxTrendMetaData?: PxTrendMetaData
}

export type Equipment = {
  id: string
  name: string
  matchingId: string
  type: PlantEquipmentType
  status: RunningTimeType
  productionVolume: number
}

export type PlantEquipmentStatus = {
  plant: Plant // soon deprecated
  equipments: Equipment[]
  actualCementProduction: number // soon deprecated
  actualClinkerProduction: number // soon deprecated
  plantId: string
}

export type EquipmentProductionVolumes = {
  id: string
  name: string
  matchingId: string
  type: PlantEquipmentType
  productionVolume: number
}

export type PlantEquipmentProductionVolumes = {
  plantId: string
  actualCementProduction: number
  actualClinkerProduction: number
  equipmentProductionVolumes: EquipmentProductionVolumes[]
}

export enum KpisList {
  ClinkerProduction = 'clinkerProduction',
  CementProduction = 'cementProduction',
  KilnReliabilityCoefficient = 'kilnReliabilityCoefficient',
  KilnOperatingCoefficient = 'kilnOperatingCoefficient',
  KilnHeatConsumption = 'kilnHeatConsumption',
  AlternativeFuelRate = 'alternativeFuelRate',
  MTBF = 'meanTimeBetweenFailure',
  TechnicalCementPowerCons = 'technicalCementPowerConsumption'
}

export const optionalKPIs = [
  KpisList.KilnReliabilityCoefficient,
  KpisList.KilnOperatingCoefficient,
  KpisList.KilnHeatConsumption,
  KpisList.AlternativeFuelRate,
  KpisList.MTBF,
  KpisList.TechnicalCementPowerCons
]

type ActualProductionValue = {
  plannedCementProduction: number | null
  plannedClinkerProduction: number | null
  actualCementProduction: number
  actualClinkerProduction: number
  cementDelta: number
  clinkerDelta: number
  plantId: string
  unit: string
}

export type PlantStatusResponse = {
  plant: Plant
  totalCement: number
  totalClinker: number
  unitCement: string
  unitClinker: string
  plannedYearlyProductionVolume: PlannedYearlyProductionVolumeResponse
  actualProductionValue?: ActualProductionValue
  // TODO: remove running times from here
  // https://jira.heidelbergcement.com/browse/HCP-74800
  equipments: Equipment[]
  equipmentRunningTimes?: EquipmentRunningTimesResult
}

export type PlantStatus = {
  plant: Plant
  plannedCementProduction: number
  plannedClinkerProduction: number
  actualCementProduction?: number
  actualClinkerProduction?: number
  totalCement: number
  totalClinker: number
  clinkerDelta: number
  cementDelta: number
  unitCement: string
  unitClinker: string
  equipmentRunningTimes?: EquipmentRunningTimesResult
  equipments: Equipment[]
  plannedYearlyProductionVolume: PlannedYearlyProductionVolumeResponse
}

export type LocationStatus = {
  locationId: string
  locationName: string
  locationType: LocationType
  countryCode?: string
  plannedCementProduction: number
  plannedClinkerProduction: number
  actualCementProduction: number
  actualClinkerProduction: number
  totalCement: number
  totalClinker: number
  clinkerDelta: number
  cementDelta: number
  unitCement?: string
  unitClinker?: string
  descendants?: LocationStatus[]
  plantStatuses: PlantStatus[]
}

export type LocationStatusResponse = {
  locationId: string
  locationName: string
  locationType: LocationType
  countryCode?: string
  plannedCementProduction: number | null
  plannedClinkerProduction: number | null
  actualCementProduction: number
  actualClinkerProduction: number
  totalCement: number
  totalClinker: number
  clinkerDelta: number
  cementDelta: number
  unitCement?: string
  unitClinker?: string
  descendants?: LocationStatusResponse[]
  plantStatuses: PlantStatusResponse[]
}

export type ActualAndPlannedData = {
  unit: string
  actual?: number
  planned: number
  delta: number
}

export interface KPIs {
  alternativeFuelRate: ActualAndPlannedData
  kilnHeatConsumption: ActualAndPlannedData
  kilnReliabilityCoefficient: ActualAndPlannedData
  meanTimeBetweenFailure: ActualAndPlannedData
  kilnOperatingCoefficient: ActualAndPlannedData
  technicalCementPowerConsumption: ActualAndPlannedData
}

export interface KPIsWithProduction extends KPIs {
  clinkerProduction: ActualAndPlannedData
  cementProduction: ActualAndPlannedData
}

export type ProductionActualAndPlannedData = {
  data: ActualAndPlannedData | undefined
  isError: boolean
}

export type PlantKPIs = Partial<
  Record<
    keyof KPIs,
    {
      data: ActualAndPlannedData | undefined
      isError: boolean
    }
  >
>

export type ProductionDataType = {
  unit: string
  planned: number
  actual: number | undefined
  delta: number
}
