import {ContentBox} from '@hconnect/common/components/ContentBox'
import {EquipmentPerformanceResult} from '@hconnect/common/components/kpiPerformance/EquipmentPerformanceResult'
import {OperationHoursLegendTooltip} from '@hconnect/common/components/runningTimes'
import {EquipmentRunningTimes, RunningTime} from '@hconnect/common/types'
import {getTranslationKey} from '@hconnect/common/utils/translation.utils'
import {DateRange} from '@hconnect/uikit'
import {Typography} from '@mui/material'
import {get, isEmpty, last} from 'lodash'
import React, {FC, ReactNode, useMemo} from 'react'
import {useTranslation} from 'react-i18next'
import {generatePath, useNavigate} from 'react-router-dom'

import {useRunningTimes} from '../hooks/useRunningTimes'
import {usePlantTimeRange} from '../hooks/useTimeRange'
import {useTranslationPrefix} from '../hooks/useTranslationPrefix'
import {STOPPAGE_ANALYSIS, STOPPAGE_ANALYSIS_STATUS} from '../routing'
import {EquipmentData, TimeRange} from '../types'

type PlantRunningTimesProps = {
  plantId: string
  getStackedBarChartComponent: (equipment: EquipmentData, runningTimes: RunningTime[]) => ReactNode
}

export const PlantRunningTimes: FC<PlantRunningTimesProps> = ({
  plantId,
  getStackedBarChartComponent
}) => {
  const {t} = useTranslation()
  const {performancePrefix} = useTranslationPrefix()
  const navigate = useNavigate()

  const {from, to}: DateRange<Date> = usePlantTimeRange({plantId})
  const timeRange: TimeRange = useMemo(
    () => ({startDate: from.toJSON(), endDate: to.toJSON()}),
    [from, to]
  )

  const {data: runningTimePerformances, isLoading: isRunningTimePerformanceLoading} =
    useRunningTimes(timeRange, plantId)

  const redirectToStoppageAnalysis = (equipmentId: string) => {
    navigate(
      generatePath(performance ? STOPPAGE_ANALYSIS : STOPPAGE_ANALYSIS_STATUS, {
        plantId,
        equipmentId
      })
    )
  }

  return (
    <ContentBox
      mode="max100PercentOfParentHeight"
      title={
        <Typography component="span" variant="h3" display="flex" alignItems="center">
          <>
            {t(getTranslationKey('runningTimes.label.title', performancePrefix))}
            {!isEmpty(runningTimePerformances) && (
              <OperationHoursLegendTooltip translationPrefix={performancePrefix} />
            )}
          </>
        </Typography>
      }
      isLoading={isRunningTimePerformanceLoading}
      bodyWithPadding
      data-test-id="plant-running-times"
    >
      {runningTimePerformances?.map(
        (equipmentPerformanceData: EquipmentRunningTimes, index: number) => {
          const activeStatus = get(last(equipmentPerformanceData.runningTimes), 'runningTimeType')
          return (
            <EquipmentPerformanceResult
              translationPrefix={performancePrefix}
              runningTimePerformance={equipmentPerformanceData}
              equipment={equipmentPerformanceData.equipment}
              key={equipmentPerformanceData.equipment.id}
              getStackedBarChartComponent={getStackedBarChartComponent}
              isLast={runningTimePerformances?.length === index + 1}
              expandButtonGap={!isEmpty(runningTimePerformances)}
              activeStatus={activeStatus}
              onClick={() => redirectToStoppageAnalysis(equipmentPerformanceData.equipment.id)}
            />
          )
        }
      )}
    </ContentBox>
  )
}
