import moment from 'moment-timezone'
import React from 'react'
import {useTranslation} from 'react-i18next'

import {TimeZone, DateTime} from '../types'
import {dateFormatter} from '../utils'

type DateFormatProps = {
  date: DateTime
  timezone: TimeZone
}

export const DateFormat: React.FC<DateFormatProps> = ({date, timezone}) => {
  const {i18n} = useTranslation()
  const utcOffset = moment(date).tz(timezone).utcOffset()
  return <>{dateFormatter(date, i18n.language, utcOffset)}</>
}
