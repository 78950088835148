import {ContentBox} from '@hconnect/common/components/ContentBox'
import {EventTypeTag} from '@hconnect/common/components/shiftEventFormFields'
import {Stoppage} from '@hconnect/common/types'
import {validateAll} from '@hconnect/common/validators'
import {Check, Close} from '@mui/icons-material'
import {Box, Button} from '@mui/material'
import React, {useCallback, useEffect, useState} from 'react'
import {useTranslation} from 'react-i18next'

import {useTranslationPrefix} from '../hooks/useTranslationPrefix'

import {StoppageForm, stoppageValidatorConfig} from './StoppageForm'

export type EventFormContainerProps = {
  item: Partial<Stoppage>
  doClose(): void
  doSubmit(item: Stoppage): void
  plantId: string
  isCreateMode?: boolean
  children?: React.ReactNode
  renderFormHeaderActions?: (props: {doClose: () => void}) => React.ReactNode
  renderFormFooterActions?: (props: {
    doSubmit: () => void
    doClose: () => void
    isDirty: boolean
    isSubmitDisabled: boolean
  }) => React.ReactNode
  bodyWithPadding?: boolean
  withSalesImpact?: boolean
  withPriority?: boolean
  withCategory?: boolean
  eventTypeLabelPrefix?: string
}

type State = {
  originalItem: Partial<Stoppage>
  item: Partial<Stoppage>
  showValidation: boolean
  validationError: Map<keyof Stoppage, string>
}

export const getTypeSpecificValidatorConfig = () => {
  return stoppageValidatorConfig
}

// helper to maintain a fix pointer for an empty validationError map
const emptyMap = new Map()

export const StoppageFormContainer: React.FC<EventFormContainerProps> = ({
  item: originalItem,
  doClose,
  doSubmit: parentDoSubmit,
  children,
  plantId,
  renderFormHeaderActions,
  renderFormFooterActions,
  bodyWithPadding = true,
  withSalesImpact = true,
  withPriority,
  withCategory,
  eventTypeLabelPrefix
}) => {
  // state setup
  const [state, setState] = useState<State>({
    originalItem,
    item: originalItem,
    showValidation: false,
    validationError: emptyMap
  })

  useEffect(() => {
    setState((currentState: State) => ({
      ...currentState,
      originalItem,
      item: originalItem
    }))
  }, [originalItem])

  // variables
  const item = state.item
  const validatorConfig = getTypeSpecificValidatorConfig()
  const disableSubmit = state.showValidation && state.validationError.size > 0
  const isDirty = JSON.stringify(state.originalItem) !== JSON.stringify(state.item)

  // hooks
  const {performancePrefix} = useTranslationPrefix()
  const {t} = useTranslation()

  // callbacks
  const doSubmit = () => {
    const validationError = validateAll<Stoppage>(validatorConfig, item)

    if (validationError.size === 0) {
      parentDoSubmit(item as Stoppage)
    } else {
      setState((currentState: State) => ({
        ...currentState,
        showValidation: true,
        validationError
      }))
    }
  }

  const updateMultiPart = useCallback(
    (delta: Partial<Stoppage>) => {
      setState((currentState: State) => {
        const nextItem = {
          ...currentState.item,
          ...delta
        } as Stoppage

        const validationError = currentState.showValidation
          ? validateAll<Stoppage>(validatorConfig, nextItem) // TODO instead of validate all, just validate the "keyof delta"
          : currentState.validationError

        return {
          ...currentState,
          item: nextItem,
          validationError
        }
      })
    },
    [setState, validatorConfig]
  )

  return (
    <ContentBox
      data-test-id="event-form"
      bodyWithPadding={bodyWithPadding}
      title={
        item.eventType && (
          <EventTypeTag
            eventType={item.eventType}
            translationPrefix={performancePrefix}
            eventTypeLabelPrefix={eventTypeLabelPrefix}
          />
        )
      }
      afterTitle={renderFormHeaderActions ? renderFormHeaderActions({doClose}) : undefined}
      mode="max100PercentOfParentHeight"
    >
      <StoppageForm
        plantId={plantId}
        item={item}
        updateMultiPart={updateMultiPart}
        validationError={state.showValidation ? state.validationError : emptyMap}
        withSalesImpact={withSalesImpact}
        withPriority={withPriority}
        withCategory={withCategory}
      >
        {children}
      </StoppageForm>
      {renderFormFooterActions ? (
        renderFormFooterActions({doSubmit, doClose, isDirty, isSubmitDisabled: disableSubmit})
      ) : (
        <Box mt={3} display="flex" justifyContent="flex-end" gap={2}>
          <Button
            startIcon={<Close />}
            variant="text"
            onClick={doClose}
            data-test-id="event-form-cancel-button"
          >
            {t(`${performancePrefix}.shiftEvent.action.cancel`)}
          </Button>
          <Button
            startIcon={<Check />}
            disabled={disableSubmit}
            color="primary"
            variant="contained"
            onClick={doSubmit}
            data-test-id="event-save-button"
          >
            {t(`${performancePrefix}.shiftEvent.action.save`)}
          </Button>
        </Box>
      )}
    </ContentBox>
  )
}
