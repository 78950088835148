import {KeyboardArrowRight, KeyboardArrowDown} from '@mui/icons-material'
import {
  IconButton,
  Table as MaterialTable,
  TableBody,
  TableContainer,
  TableRow,
  TableCell,
  Collapse,
  Box,
  TableHead,
  SxProps,
  TableCellProps,
  Theme
} from '@mui/material'
import {get, isEmpty, isUndefined, uniqueId} from 'lodash'
import React, {useState, useCallback, ElementType} from 'react'

export type ColumnsConfig<T> = {
  key: string
  label: string | JSX.Element
  template?: ElementTypeWithRowData<T>
  headerSx?: SxProps<Theme>
  cellProps?: TableCellProps
}[]

type TableRowData<T> = {rowData: T}

export type ElementTypeWithRowData<T> = ElementType<TableRowData<T>>

type CollapsibleTableWithoutPaginationProps<T> = {
  data: T[]
  config: ColumnsConfig<T>
  collapseContent?: ElementTypeWithRowData<T>
  expandAll?: boolean
}

type RowProps<T> = {
  config: ColumnsConfig<T>
  item: T
  collapseContent?: ElementTypeWithRowData<T>
  index: number
  toggleRow: (index: number) => void
  isOpen: boolean
}

export function Row<T>({
  isOpen,
  toggleRow,
  config,
  item,
  collapseContent: CollapseContent,
  index
}: RowProps<T>) {
  const tableCellSx = CollapseContent ? {borderBottom: 'unset'} : {}

  return (
    <>
      <TableRow
        data-test-id={`tr-${index}`}
        sx={{
          '&:last-child td': {
            borderBottom: 0
          }
        }}
      >
        {CollapseContent && (
          <TableCell
            width={1}
            sx={{
              fontSize: 14,
              py: 2,
              ...tableCellSx
            }}
          >
            <IconButton
              size="small"
              onClick={() => toggleRow(index)}
              data-test-id={`table-row-expand-button-${index}`}
            >
              {isOpen ? <KeyboardArrowDown /> : <KeyboardArrowRight />}
            </IconButton>
          </TableCell>
        )}

        {config.map(({template: Template, key, cellProps}) => {
          const node = get(item, key)
          const isTemplate = !isUndefined(Template)

          return (
            <TableCell
              {...cellProps}
              key={`table-cell-${key}-${uniqueId()}`}
              sx={{
                fontSize: 14,
                py: 2,
                ...tableCellSx
              }}
            >
              {isTemplate ? <Template rowData={item} /> : node}
            </TableCell>
          )
        })}
      </TableRow>

      {CollapseContent && (
        <TableRow data-test-id={`tr-collapse-${index}`}>
          <TableCell sx={{py: 0}} colSpan={6}>
            <Collapse in={isOpen} timeout="auto" unmountOnExit>
              <Box margin={1}>
                <CollapseContent rowData={item} />
              </Box>
            </Collapse>
          </TableCell>
        </TableRow>
      )}
    </>
  )
}

/**
 * Table component uses MUI table and supports expandable rows without pagination
 * @param CollapsibleTableWithoutPaginationProps
 * @returns
 */
export function CollapsibleTableWithoutPagination<T>({
  data,
  config,
  collapseContent: CollapseContent,
  expandAll
}: CollapsibleTableWithoutPaginationProps<T>) {
  const isCollapseContent = CollapseContent && !isEmpty(data)
  const [expandedRows, setExpandedRows] = useState<number[]>([])

  const toggleRow = useCallback(
    (index: number) => {
      if (expandedRows.includes(index)) {
        setExpandedRows(expandedRows.filter((rowIndex) => rowIndex !== index))
      } else {
        setExpandedRows([...expandedRows, index])
      }
    },
    [expandedRows]
  )

  return (
    <TableContainer>
      <MaterialTable>
        <TableHead>
          <TableRow>
            {isCollapseContent && <TableCell />}
            {config.map(({label, headerSx, key}) => (
              <TableCell sx={headerSx} key={`table-header-${key}`}>
                {label}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((item, index) => (
            <Row<T>
              collapseContent={CollapseContent}
              config={config}
              item={item}
              key={uniqueId('table-row-')}
              index={index}
              toggleRow={toggleRow}
              isOpen={expandAll ? expandAll : expandedRows.includes(index)}
            />
          ))}
        </TableBody>
      </MaterialTable>
    </TableContainer>
  )
}
