import React, {useEffect, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {useNavigate} from 'react-router'

import {useNavAccessContext} from '../../shared/hooks/useNavAccessContext'
import {getQuickSelectionItems, useTimeRange} from '../hooks/useTimeRange'
import {useTimeRangeParam} from '../hooks/useTimeRangeParam'
import {getSystemTimezone} from '../hooks/useTimezone'
import {useTranslationPrefix} from '../hooks/useTranslationPrefix'

import {ActualVsPlanned} from './ActualVsPlanned'

type Props = {
  timezone?: string
  metricsComponent?: {
    component: React.ElementType
    props?: any
  }
  type?: 'status' | 'performance' | 'all'
}

export const PerformanceStatusWrapper: React.FC<Props> = ({
  metricsComponent: {
    component: MetricsComponent = ActualVsPlanned,
    props: metricsComponentProps = {}
  } = {},
  type = 'performance',
  timezone = getSystemTimezone()
}) => {
  const navigate = useNavigate()
  const {performancePrefix} = useTranslationPrefix()
  const {quickSelectionId} = useTimeRange()
  const {t} = useTranslation()
  const [timeRangeLoaded, setTimeRangeLoaded] = useState<boolean>(false)

  const [timeRange, setTimeRange] = useTimeRangeParam()
  const selectionItems = getQuickSelectionItems(
    (key) => t(`${performancePrefix}.${key}`),
    timezone,
    new Date(),
    type
  )

  const {allowMultipleLocations, getRedirectURL, getRedirectStatusURL} = useNavAccessContext()

  useEffect(() => {
    if (!allowMultipleLocations()) {
      const redirectURL = type !== 'performance' ? getRedirectStatusURL() : getRedirectURL()

      if (redirectURL) {
        navigate(redirectURL)
      }
    }
  }, [allowMultipleLocations, getRedirectURL, navigate, getRedirectStatusURL, type])

  useEffect(() => {
    if (quickSelectionId && timeRange && !selectionItems.find((item) => item.id === timeRange)) {
      setTimeRange(selectionItems[0].id)
    }
    setTimeRangeLoaded(true)
  }, [quickSelectionId, selectionItems, setTimeRange, timeRange])

  const showKPIs = quickSelectionId !== 'last3months'

  if (!timeRangeLoaded) {
    return <div />
  }
  return (
    <>
      <MetricsComponent showFilteredMetrics={!showKPIs} {...metricsComponentProps} />
    </>
  )
}
