import {AxiosError} from 'axios'
import {isEmpty} from 'lodash'
import {useMemo} from 'react'

import {checkIfQuarterlyDataExists} from '../../helpers'
import {attachQuarterlyData} from '../../mappers'
import {
  AggregatedKpiData,
  HierarchyNodeType,
  KpiData,
  KpiDataPayload,
  KpisList,
  TimeRange
} from '../../types'
import {showYtmData, useTimeRange} from '../useTimeRange'

import {KpiValue} from './commonKpiApiTypes'
import {useAlternativeFuelRatesData} from './useAlternativeFuelRatesData'
import {useKilnCoefficientsData} from './useKilnCoefficientsData'
import {useKilnHeatConsumptionData} from './useKilnHeatConsumptionData'
import {useKpiQuarterlyValue} from './useKpiQuarterlyValue'
import {useMeanTimeBetweenFailuresData} from './useMeanTimeBetweenFailuresData'
import {useProductionPerformanceValues} from './useProductionPerformanceValues'
import {useTechnicalCementPowerConsumptionData} from './useTechnicalCementPowerConsumptionData'

// clinker and cement hooks use different amount of nested hooks than the rest of kpis
// so to keep rendered hooks consistency both should be engaged at the same time
const TAB_API_MAPPING = {
  [KpisList.KilnReliabilityCoefficient]: useKilnCoefficientsData,
  [KpisList.KilnOperatingCoefficient]: useKilnCoefficientsData,
  [KpisList.KilnHeatConsumption]: useKilnHeatConsumptionData,
  [KpisList.AlternativeFuelRate]: useAlternativeFuelRatesData,
  [KpisList.MTBF]: useMeanTimeBetweenFailuresData,
  [KpisList.TechnicalCementPowerCons]: useTechnicalCementPowerConsumptionData
}

type AggregatedKpiValue = {
  data?: AggregatedKpiData
  isLoading: boolean
  error: AxiosError | null
  refetch: () => void
  hasQuarterlyData: boolean
}

export const useKpiValue = (currentTab: KpisList, locationId?: string): AggregatedKpiValue => {
  const {from, to, quickSelectionId} = useTimeRange()
  const dateRange: TimeRange = {startDate: from.toJSON(), endDate: to.toJSON()}

  const useApiHook = useMemo<(payload: KpiDataPayload, enabled?: boolean) => KpiValue>(() => {
    return TAB_API_MAPPING[currentTab] || useKilnCoefficientsData
  }, [currentTab])

  const isCementClinkerTab =
    currentTab === KpisList.ClinkerProduction || currentTab === KpisList.CementProduction

  const {
    data: kpiData,
    isLoading: kpiLoading,
    error: kpiError,
    refetch: kpiRefetch
  } = useApiHook(
    {
      timeRange: dateRange,
      locationId,
      showYTM: showYtmData(quickSelectionId)
    },
    !isCementClinkerTab
  )
  const {
    data: productionPerformanceData,
    isLoading: productionPerformanceLoading,
    error: productionPerformanceError,
    refetch: productionPerformanceRefetch
  } = useProductionPerformanceValues(
    {
      timeRange: dateRange,
      locationId,
      showYTM: showYtmData(quickSelectionId)
    },
    {enabled: isCementClinkerTab}
  )

  const {
    data: kpiQuarterlyData,
    isLoading: isLoadingQuarterly,
    error: errorQuarterly,
    refetch: refetchQuarterly
  } = useKpiQuarterlyValue(currentTab, locationId || HierarchyNodeType.Global)

  return useMemo(() => {
    const data = isCementClinkerTab ? (productionPerformanceData as KpiData) : kpiData
    const isLoading = isCementClinkerTab ? productionPerformanceLoading : kpiLoading
    const error = isCementClinkerTab ? productionPerformanceError : kpiError
    const refetch = isCementClinkerTab ? productionPerformanceRefetch : kpiRefetch

    const hasQuarterlyData =
      !isEmpty(kpiQuarterlyData) &&
      checkIfQuarterlyDataExists(currentTab, kpiQuarterlyData, locationId)
    const aggregatedData: AggregatedKpiData | null = attachQuarterlyData(
      currentTab,
      data,
      kpiQuarterlyData || undefined
    )

    return {
      data: aggregatedData || data,
      isLoading: isLoading || isLoadingQuarterly,
      error: error || errorQuarterly,
      refetch: () => {
        const refetchPromises = [refetch(), refetchQuarterly()]
        void Promise.all(refetchPromises)
      },
      hasQuarterlyData
    }
  }, [
    currentTab,
    isCementClinkerTab,
    kpiData,
    kpiError,
    kpiLoading,
    kpiRefetch,
    productionPerformanceData,
    productionPerformanceError,
    productionPerformanceLoading,
    productionPerformanceRefetch,
    kpiQuarterlyData,
    isLoadingQuarterly,
    errorQuarterly,
    refetchQuarterly,
    locationId
  ])
}
