import Markdown, {MarkdownToJSX} from 'markdown-to-jsx'
import React from 'react'

interface MarkdownTextProps {
  children: string
  options?: MarkdownToJSX.Options
}

const defaultOptions: MarkdownToJSX.Options = {
  overrides: {
    a: {
      props: {
        target: '_blank',
        rel: 'noopener noreferrer'
      }
    }
  }
}

export const MarkdownText: React.FC<MarkdownTextProps> = ({children, options}) => (
  <Markdown options={{...defaultOptions, ...options}}>{children}</Markdown>
)
