import {ChecklistTemplatesList} from '../components/ChecklistTemplatesList'
import {DataContentWrapper} from '../components/DataContentWrapper'
import {useChecklistTemplates} from '../hooks/api'

export const ChecklistTemplatesListContainer = () => {
  const {data, isLoading, isRefetching, isError, refetch} = useChecklistTemplates()

  return (
    <DataContentWrapper
      isLoading={isLoading}
      isError={isError}
      data={data}
      retryFunction={() => void refetch()}
      isRefetching={isRefetching}
    >
      <ChecklistTemplatesList data={data} />
    </DataContentWrapper>
  )
}
