import {Route} from 'react-router-dom'

import {Context} from '../shared/types/temp.types'

import {MyTasks} from './pages/MyTasks'
import {MY_TASKS} from './routing'

export const getMyTasksRoutes = (context: Context) => {
  return [<Route key="my-tasks" path={MY_TASKS} element={<MyTasks context={context} />} />]
}
