import {type Permission} from '@hconnect/apiclient'
import {NavItem} from '@hconnect/uikit/src/lib2'
import {useMemo} from 'react'
import {useTranslation} from 'react-i18next'
import {generatePath, useParams} from 'react-router-dom'

import {checkFeatureFlag} from '../../shared/helpers/featureFlagHelpers'
import {useTranslationPrefix} from '../../shared/hooks/useTranslationPrefix'
import {hasChecklistsPermission} from '../utils/permissions'

import {CHECKLISTS, PLANT_CHECKLISTS_TEMPLATES} from './routes'

type PathParameter = {
  plantId?: string
}

export const useGetChecklistLinks = ({permissions}: {permissions: Permission[]}): NavItem[] => {
  const {plantId} = useParams<PathParameter>()
  const {t} = useTranslation()
  const {checklistsPrefix} = useTranslationPrefix()

  const areChecklistsEnabled = checkFeatureFlag('checklists')
  const userHasChecklistsPermission = useMemo(
    () => hasChecklistsPermission(permissions),
    [permissions]
  )

  const checklistNavItem: NavItem = {
    url: plantId ? generatePath(PLANT_CHECKLISTS_TEMPLATES, {plantId}) : CHECKLISTS,
    label: t(`${checklistsPrefix}.navPages.checklists`)
  }

  const navItems: NavItem[] = []

  if (areChecklistsEnabled && userHasChecklistsPermission) {
    navItems.push(checklistNavItem)
  }

  return navItems
}
