import {Iso8601, Stoppage} from '@hconnect/common/types'
import {isStoppageTypeWithCode, isStoppageTypeWithReason} from '@hconnect/common/utils'
import {shouldNotBeEmpty, Validator} from '@hconnect/common/validators'
import moment from 'moment-timezone'

const COST_PATTERN = /^(?!0\.00)(?:\d*\.\d{2}|\d+)$/

export const requiredEquipmentNumberValidator: Validator<Stoppage> = (value: string, item) => {
  return item.equipment ? undefined : 'shouldBe.notEmpty'
}

export const stoppageCodeValidator: Validator<Stoppage> = (
  value: string,
  item: Partial<Stoppage>
) => {
  if (item.stoppageType && isStoppageTypeWithCode(item.stoppageType)) {
    return value && value.trim().length > 0 ? undefined : 'shouldBe.notEmpty'
  }
}

export const stoppageReasonValidator: Validator<Stoppage> = (
  value: string,
  item: Partial<Stoppage>
) => {
  if (item.stoppageType && isStoppageTypeWithReason(item.stoppageType)) {
    return value && value.trim().length > 0 ? undefined : 'shouldBe.notEmpty'
  }
}

export const costValidator: Validator<Stoppage> = (value: string, item: Partial<Stoppage>) => {
  const emptyValidation = shouldNotBeEmpty<Stoppage>()(value, item)
  if (emptyValidation) {
    return emptyValidation
  }
  const isValidFormat = COST_PATTERN.test(value)
  if (!isValidFormat) {
    return 'shouldBe.validCostFormat'
  }
}

export const validToValidator: Validator<Stoppage> = (
  date: Iso8601 | undefined,
  item: Partial<Stoppage>
) => {
  if (date) {
    return moment(date).isAfter(item.stoppageStart) ? undefined : 'shouldBe.dateAfter'
  }
  return undefined
}
