import {AxiosError, AxiosInstance} from 'axios'
import {useQuery, UseQueryOptions} from 'react-query'
import {useNavigate} from 'react-router-dom'

import {handleError} from '../../../shared/helpers/errorHandling'
import {useAxios} from '../../../shared/hooks/useApi'
import {MeanTimeBetweenFailuresResponse, TimeRange, TimeRangeWithOffset} from '../../types'

export const getMeanTimeBetweenFailuresQueryKey = (showYTM?: boolean) =>
  showYTM ? 'mean-time-between-failures-ytm' : 'mean-time-between-failures-mtm'

export const getMeanTimeBetweenFailures = async (
  axiosInstance: AxiosInstance,
  {timeRange, locationId, showYTM}: TimeRangeWithOffset
) => {
  const QueryKey = getMeanTimeBetweenFailuresQueryKey(showYTM)

  const payload: TimeRange = {startDate: timeRange.startDate, endDate: timeRange.endDate}

  const url = `/kpi/dashboard/${QueryKey}`
  const response = await axiosInstance.post<MeanTimeBetweenFailuresResponse>(url, payload, {
    params: {locationId}
  })

  return response.data
}

export const useMeanTimeBetweenFailures = <T>(
  {timeRange, locationId, showYTM}: TimeRangeWithOffset,
  options?: UseQueryOptions<MeanTimeBetweenFailuresResponse, AxiosError, T>
) => {
  const axiosInstance = useAxios()
  const navigate = useNavigate()
  const QueryKey = getMeanTimeBetweenFailuresQueryKey(showYTM)

  return useQuery<MeanTimeBetweenFailuresResponse, AxiosError, T>(
    [QueryKey, timeRange.startDate, timeRange.endDate, locationId],
    async () => {
      return getMeanTimeBetweenFailures(axiosInstance, {timeRange, locationId, showYTM})
    },
    {
      onError: (err) => handleError(err, navigate),
      ...options
    }
  )
}
