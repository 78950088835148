import {DateRange} from '@hconnect/uikit'
import {AxiosError} from 'axios'
import React, {useMemo, FC} from 'react'
import {QueryObserverSuccessResult} from 'react-query'

import {useAreYTDFilterKPIsAcVsPlAllowed} from '../hooks/useAreYTDFilterKPIsAcVsPlAllowed'
import {usePlantTimeRange} from '../hooks/useTimeRange'
import {PlantStatus} from '../types'

import {PlantKpiData} from './PlantKpiData'

type PlantActualVsPlannedProps = {
  plantStatusQuery: QueryObserverSuccessResult<PlantStatus, AxiosError>
}

export const PlantActualVsPlanned: FC<PlantActualVsPlannedProps> = ({plantStatusQuery}) => {
  const {data: plantData, error: plantError, isLoading: plantIsLoading} = plantStatusQuery
  const {
    plant: {plantId, pxTrendMetaData}
  } = plantData
  const {quickSelectionId}: DateRange<Date> = usePlantTimeRange({plantId})

  const showKPIsAcVsPl =
    useAreYTDFilterKPIsAcVsPlAllowed() &&
    quickSelectionId &&
    [
      'lastYear',
      'yearToDateYTD',
      'last3months',
      'yearToMonthYTM',
      'currentMonth',
      'lastMonth'
    ].includes(quickSelectionId)

  const isGrindingUnit = pxTrendMetaData?.isGrindingUnit

  const clinkerProductionData = useMemo(() => {
    return {
      unit: plantData.unitCement,
      planned: plantData.plannedClinkerProduction,
      actual: plantData.actualClinkerProduction,
      delta: plantData.clinkerDelta
    }
  }, [plantData])
  const cementProductionData = useMemo(() => {
    return {
      unit: plantData.unitClinker,
      planned: plantData.plannedCementProduction,
      actual: plantData.actualCementProduction,
      delta: plantData.cementDelta
    }
  }, [plantData])

  const productionData = useMemo(() => {
    return {
      cementProduction: {data: cementProductionData, isError: plantError},
      clinkerProduction: {data: clinkerProductionData, isError: plantError}
    }
  }, [cementProductionData, clinkerProductionData, plantError])

  if (plantIsLoading) {
    return null
  }

  return (
    <PlantKpiData
      showKPIs={showKPIsAcVsPl}
      productionData={productionData}
      plantId={plantId}
      isGrindingUnit={isGrindingUnit}
    />
  )
}
