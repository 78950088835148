import React from 'react'

import {ChecklistTemplatesTable} from '../components/ChecklistTemplatesTable'
import {DataContentWrapper} from '../components/DataContentWrapper'
import {useChecklistTemplates} from '../hooks/api'

export const ChecklistTemplatesContainer = () => {
  const {data, isLoading, isFetching, isRefetching, isError, refetch} = useChecklistTemplates()

  return (
    <DataContentWrapper
      isLoading={isLoading}
      isError={isError}
      data={data}
      retryFunction={() => void refetch()}
      isRefetching={isRefetching}
      isNoData={(data) => !data}
      progressSx={{color: 'primary.main'}}
    >
      <ChecklistTemplatesTable data={data} isLoading={isFetching} />
    </DataContentWrapper>
  )
}
