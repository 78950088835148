import {StoppageFormFields} from '@hconnect/common/components/stoppageFormFields'
import {ConfigContext} from '@hconnect/common/hooks/ConfigContext'
import {Stoppage} from '@hconnect/common/types'
import {
  shouldNotBeEmpty,
  shouldNotBeEmptyString,
  ValidatorConfig
} from '@hconnect/common/validators'
import React from 'react'

import {FeatureFlag} from '../../shared/helpers/featureFlagHelpers'
import {useConfig} from '../hooks/configService'
import {useTranslationPrefix} from '../hooks/useTranslationPrefix'
import {
  requiredEquipmentNumberValidator,
  stoppageCodeValidator,
  stoppageReasonValidator,
  validToValidator
} from '../validators'

import {DataContentWrapper} from './DataContentWrapper'

type PossibleErrors = keyof Stoppage | 'stoppageCode' | 'stoppageReason' | 'equipmentNumber'

type StoppageFormProps = {
  item: Partial<Stoppage>
  updateMultiPart: (delta: Partial<Stoppage>) => void
  validationError: Map<PossibleErrors, string>
  plantId: string
  children?: React.ReactNode
  withSalesImpact?: boolean
  withPriority?: boolean
  withCategory?: boolean
}

export const stoppageValidatorConfig: ValidatorConfig<Stoppage> = new Map([
  ['title', shouldNotBeEmptyString<Stoppage>()],
  ['equipmentNumber', requiredEquipmentNumberValidator],
  ['eventType', shouldNotBeEmpty<Stoppage>()],
  ['stoppageCode', stoppageCodeValidator],
  ['stoppageReason', stoppageReasonValidator],
  ['stoppageStart', shouldNotBeEmpty<Stoppage>()],
  ['stoppageEnd', validToValidator],
  ['description', shouldNotBeEmptyString<Stoppage>()]
  // HCP-42934 uncomment when management decides
  // ['costCurrency', shouldNotBeEmptyString<Stoppage>()],
  // ['cost', costValidator]
])

export const StoppageForm: React.FC<StoppageFormProps> = ({
  children,
  item,
  updateMultiPart,
  validationError,
  plantId,
  withSalesImpact,
  withPriority,
  withCategory
}) => {
  const {performancePrefix} = useTranslationPrefix()
  const {data, isLoading, error, refetch} = useConfig(plantId)

  const renderContent = () =>
    data && (
      <StoppageFormFields
        item={item}
        updateMultiPart={updateMultiPart}
        validationError={validationError}
        timezone={data?.timezone}
        plantId={data?.plantId}
        withSalesImpact={withSalesImpact}
        withPriority={withPriority}
        withCategory={withCategory}
        hideStoppageType={true}
        stoppageCodes={{
          kilnStoppageCodes: data?.kilnStoppageCodes,
          rawMillStoppageCodes: data?.rawMillStoppageCodes,
          finishMillStoppageCodes: data?.finishMillStoppageCodes
        }}
        translationPrefix={performancePrefix}
      >
        {children}
      </StoppageFormFields>
    )

  return (
    <DataContentWrapper<ConfigContext<FeatureFlag>>
      isLoading={isLoading}
      data={data}
      retryFunction={() => void refetch()}
      error={error}
      renderContent={renderContent}
    />
  )
}
