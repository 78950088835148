import {useEffect, useState} from 'react'
import {useTranslation} from 'react-i18next'

import {getQuickSelectionItems} from './useTimeRange'
import {useTimeRangeParam} from './useTimeRangeParam'
import {getSystemTimezone} from './useTimezone'
import {useTranslationPrefix} from './useTranslationPrefix'

export const useStatusInitialTimeRange = () => {
  const [timeRangeLoaded, setTimeRangeLoaded] = useState<boolean>(false)
  const timezone = getSystemTimezone()
  const {t} = useTranslation()
  const {performancePrefix} = useTranslationPrefix()
  const [timeRange, setTimeRange] = useTimeRangeParam()
  const selectionItems = getQuickSelectionItems(
    (key) => t(`${performancePrefix}.${key}`),
    timezone,
    new Date(),
    'status'
  )

  useEffect(() => {
    if (timeRange && !selectionItems.find((item) => item.id === timeRange)) {
      setTimeRange(selectionItems[0].id)
    }
    setTimeRangeLoaded(true)
  }, [selectionItems, setTimeRange, timeRange])

  return timeRangeLoaded
}
