import {Iso8601} from '@hconnect/uikit'
import {AxiosError, AxiosInstance} from 'axios'
import {useQuery, UseQueryOptions} from 'react-query'
import {useNavigate} from 'react-router-dom'

import {handleError} from '../../../shared/helpers/errorHandling'
import {useAxios} from '../../../shared/hooks/useApi'
import {
  QuarterlyForecastTechnicalCementPowerConsumptionsHierarchy,
  TechnicalCementPowerConsumptionResponse,
  TimeRange,
  TimeRangeWithOffset
} from '../../types'

export const getTechnicalCementPowerConsumptionQueryKey = (showYTM?: boolean) =>
  showYTM ? 'technical-cement-power-consumption-ytm' : 'technical-cement-power-consumption-mtm'

export const getTechnicalCementPowerConsumption = async <T>(
  axiosInstance: AxiosInstance,
  {timeRange, locationId, showYTM}: TimeRangeWithOffset
) => {
  const QueryKey = getTechnicalCementPowerConsumptionQueryKey(showYTM)

  const payload: TimeRange = {startDate: timeRange.startDate, endDate: timeRange.endDate}
  const url = `/kpi/dashboard/${QueryKey}`

  const response = await axiosInstance.post<T>(url, payload, {params: {locationId}})

  return response.data
}

export const useTechnicalCementPowerConsumption = <T>(
  {timeRange, locationId, showYTM}: TimeRangeWithOffset,
  options?: UseQueryOptions<TechnicalCementPowerConsumptionResponse, AxiosError, T>
) => {
  const axiosInstance = useAxios()
  const navigate = useNavigate()
  const QueryKey = getTechnicalCementPowerConsumptionQueryKey(showYTM)

  return useQuery<TechnicalCementPowerConsumptionResponse, AxiosError, T>(
    [QueryKey, timeRange.startDate, timeRange.endDate, locationId],
    async () => {
      return getTechnicalCementPowerConsumption(axiosInstance, {timeRange, locationId, showYTM})
    },
    {
      onError: (err) => handleError(err, navigate),
      ...options
    }
  )
}

export const useTechnicalCementPowerConsumptionsQuarterly = (
  timestamp: Iso8601,
  locationId?: string,
  options?: UseQueryOptions<
    QuarterlyForecastTechnicalCementPowerConsumptionsHierarchy | null,
    AxiosError
  >
) => {
  const axiosInstance = useAxios()
  const navigate = useNavigate()

  return useQuery<QuarterlyForecastTechnicalCementPowerConsumptionsHierarchy | null, AxiosError>(
    ['technical-cement-power-consumption-quarterly', timestamp, locationId],
    async () => {
      if (!locationId) return Promise.resolve(null)

      const response =
        await axiosInstance.get<QuarterlyForecastTechnicalCementPowerConsumptionsHierarchy>(
          '/kpi/dashboard/technical-cement-power-consumption-qf',
          {
            params: {locationId, timestamp}
          }
        )
      return response.data
    },
    {
      onError: (err) => handleError(err, navigate),
      ...options
    }
  )
}
