import EditIcon from '@material-ui/icons/Edit'
import ListIcon from '@material-ui/icons/List'
import {Stack, Typography, Button, Box} from '@mui/material'
import {useTranslation} from 'react-i18next'

import AnnouncementImageSrc from '../../assets/Announcement.svg'
import {
  BaseAnalyticsEventName,
  useTrackDowntimeAnalyticsEvent
} from '../../helpers/trackAnalyticsEvents'
import {useTranslationPrefix} from '../../hooks/useTranslationPrefix'

type NewStoppagesBannerProps = {
  newStoppagesCount: number
  setView: (view: 'list' | 'navigator' | null) => void
}

export const NewStoppagesBanner = ({newStoppagesCount, setView}: NewStoppagesBannerProps) => {
  const {t} = useTranslation()
  const {performancePrefix} = useTranslationPrefix()
  const trackAnalyticsEvent = useTrackDowntimeAnalyticsEvent()

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%'
      }}
    >
      <Stack alignItems="center" spacing={3}>
        <Box
          component="img"
          src={AnnouncementImageSrc}
          sx={{textAlign: 'center', maxWidth: 350}}
          alt={t(`${performancePrefix}.newStoppagesBanner.announcementImageAlt`)}
        />
        <Typography variant="h3" data-test-id="new-stoppages-primary-message">
          {t(`${performancePrefix}.newStoppagesBanner.label.newStoppagesCount`, {
            count: newStoppagesCount
          })}
        </Typography>
        <Typography variant="body1">
          {t(`${performancePrefix}.newStoppagesBanner.description`)}
        </Typography>
        <Stack direction="row" justifyContent="center" spacing={2} useFlexGap flexWrap="wrap">
          <Button
            data-test-id={'view-as-list-button'}
            onClick={() => {
              trackAnalyticsEvent(BaseAnalyticsEventName.UserOpenStoppagesToAmendList, {})
              setView('list')
            }}
            startIcon={<ListIcon />}
          >
            {t(`${performancePrefix}.newStoppagesBanner.actions.viewAsList`)}
          </Button>
          <Button
            variant="contained"
            data-test-id={'amend-stoppages-button'}
            onClick={() => setView('navigator')}
            startIcon={<EditIcon />}
          >
            {t(`${performancePrefix}.newStoppagesBanner.actions.amendStoppages`)}
          </Button>
        </Stack>
      </Stack>
    </Box>
  )
}
