import {FlexPage} from '@hconnect/common/components/FlexPage'
import {CardBox} from '@hconnect/uikit/src/lib2'
import {Grid, useMediaQuery, useTheme} from '@mui/material'
import {useTranslation} from 'react-i18next'

import {useTranslationPrefix} from '../../shared/hooks/useTranslationPrefix'
import {ChecklistTemplatesContainer} from '../containers/ChecklistTemplatesContainer'
import {ChecklistTemplatesListContainer} from '../containers/ChecklistTemplatesListContainer'

export const ChecklistsTemplatesPage = () => {
  const {t} = useTranslation()
  const {checklistsPrefix} = useTranslationPrefix()

  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('md'))

  return (
    <FlexPage title={t(`${checklistsPrefix}.navPages.checklists`)}>
      {isMobile ? (
        <MobileLayout checklistTemplates={<ChecklistTemplatesListContainer />} />
      ) : (
        <DesktopLayout checklistTemplates={<ChecklistTemplatesContainer />} />
      )}
    </FlexPage>
  )
}

type LayoutProps = {
  checklistTemplates: React.ReactNode
}

const DesktopLayout = ({checklistTemplates}: LayoutProps) => {
  return (
    <Grid container flexGrow={1} overflow={'hidden'} spacing={2}>
      <Grid item xs={12} height={'100%'}>
        <CardBox
          sx={{
            display: 'flex',
            flexDirection: 'column',
            height: '100%'
          }}
        >
          {checklistTemplates}
        </CardBox>
      </Grid>
    </Grid>
  )
}

const MobileLayout = ({checklistTemplates}: LayoutProps) => {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        {checklistTemplates}
      </Grid>
    </Grid>
  )
}
