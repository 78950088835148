import {BarForChart, RcfaLinks} from '@hconnect/common/components/runningTimes'
import {RunningTime, DateTime} from '@hconnect/common/types'
import {getEventStoppageType, getRunningTimeStoppageLabel} from '@hconnect/common/utils'
import {timeFormatter, TimeZone} from '@hconnect/uikit'
import {Box} from '@mui/material'
import {Theme} from '@mui/material/styles'
import {SystemStyleObject} from '@mui/system/styleFunctionSx/styleFunctionSx'
import moment, {Moment} from 'moment-timezone'
import React, {ReactNode, useMemo} from 'react'
import {useTranslation} from 'react-i18next'
import {useParams} from 'react-router'

import {useConfig} from '../../hooks/configService'
import {useTranslationPrefix} from '../../hooks/useTranslationPrefix'
import {EquipmentData} from '../../types'

export type DateRange = {
  startDate: Moment
  endDate: Moment
}

function includeGapsInRunningTimes(timeRange: DateRange, data: RunningTime[]) {
  const runningTimesWithGaps: RunningTime[] = []
  if (data.length === 0) {
    return []
  }
  if (!moment(timeRange.startDate).isSame(moment(data[0].begin))) {
    const beginElement: RunningTime = {
      begin: timeRange.startDate.toISOString(),
      end: data[0].begin,
      runningTimeType: undefined
    }

    runningTimesWithGaps.push(beginElement)
  }

  data.forEach((rt, index) => {
    runningTimesWithGaps.push(rt)
    if (data[index + 1] && !moment(rt.end).isSame(moment(data[index + 1].begin))) {
      runningTimesWithGaps.push({
        begin: rt.end,
        end: data[index + 1].begin,
        runningTimeType: undefined
      })
    }
  })

  if (!moment(timeRange.endDate).isSame(moment(data[data.length - 1].end))) {
    const endElement: RunningTime = {
      begin: data[data.length - 1].end,
      end: timeRange.endDate.toISOString(),
      runningTimeType: undefined
    }

    runningTimesWithGaps.push(endElement)
  }

  return runningTimesWithGaps
}

function getBarPercentage(
  timeStart: string | undefined,
  timeEnd: string | undefined,
  totalRunningTime: number
) {
  if (!timeStart || !timeEnd || timeStart === timeEnd) {
    return '0%'
  }

  return (
    (
      (moment.utc(timeEnd).diff(moment.utc(timeStart), 'minutes') / totalRunningTime) *
      100
    ).toString() + '%'
  )
}

export interface Props {
  timeRange: DateRange
  data: RunningTime[]
  sx?: SystemStyleObject<Theme>
  aboveBarComponent?: (rt: RunningTime) => ReactNode
  handleStoppageClick?: (rt: RunningTime, isLast: boolean) => void | Promise<void>
  equipment?: EquipmentData
}

export const TimeFormat: React.FC<{date: DateTime; timezone: TimeZone}> = ({date, timezone}) => {
  const {i18n} = useTranslation()
  const utcOffset = moment(date).tz(timezone).utcOffset()
  return <>{timeFormatter(date, i18n.language, utcOffset)}</>
}

type PathParameter = {
  plantId?: string
  equipmentId: string
}

export const StackedBarChart: React.FC<Props> = ({
  timeRange,
  data,
  sx,
  handleStoppageClick,
  aboveBarComponent,
  equipment
}) => {
  const {plantId} = useParams<PathParameter>()
  const {data: config} = useConfig(plantId)
  const {performancePrefix} = useTranslationPrefix()

  const totalRunningTime = useMemo(() => {
    return moment(timeRange.endDate).diff(moment(timeRange.startDate), 'minutes')
  }, [timeRange])

  const runningTimesWithGapsArray = useMemo(() => {
    return includeGapsInRunningTimes(timeRange, data)
  }, [timeRange, data])

  return (
    <>
      {runningTimesWithGapsArray && (
        <Box
          sx={{
            flex: '1',
            display: 'flex',
            alignItems: 'stretch',
            justifyContent: 'flex-start',
            borderRadius: 1,
            overflow: 'hidden'
          }}
        >
          {runningTimesWithGapsArray.map((rt, index) => (
            <BarForChart
              sx={sx}
              key={index}
              percentage={getBarPercentage(rt.begin, rt.end, totalRunningTime)}
              runningTime={rt}
              aboveBarComponent={
                index === runningTimesWithGapsArray.length - 1 ? aboveBarComponent?.(rt) : undefined
              }
              tooltip={{
                header:
                  config &&
                  getRunningTimeStoppageLabel(
                    {
                      kiln: config.kilnStoppageCodes,
                      cementMill: config.finishMillStoppageCodes,
                      rawMill: config.rawMillStoppageCodes
                    },
                    rt,
                    getEventStoppageType(equipment as EquipmentData)
                  ),
                footer: rt.rootCauseFailureAnalyses && plantId && (
                  <RcfaLinks
                    plantId={plantId}
                    rootCauseFailureAnalyses={rt.rootCauseFailureAnalyses}
                  />
                )
              }}
              handleStoppageClick={
                handleStoppageClick
                  ? (e) => {
                      e.stopPropagation()
                      void handleStoppageClick(rt, index === data.length - 1)
                    }
                  : undefined
              }
              translationPrefix={performancePrefix}
            />
          ))}
        </Box>
      )}
    </>
  )
}
