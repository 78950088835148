import {ContentBox} from '@hconnect/common/components/ContentBox'
import React, {Dispatch, FC, SetStateAction} from 'react'

import {useStoppage} from '../hooks/useStoppages'
import {StoppageEvent} from '../types'

import {StoppageDetails} from './StoppageDetails'
import {StoppageEdit} from './StoppageEdit'

type StoppageAnalysisSideCardProps = {
  stoppage?: StoppageEvent
  onEdit: Dispatch<SetStateAction<boolean>>
  onDelete: (stoppageEvent: StoppageEvent) => void
  stoppageId: string
  isEditMode: boolean
  onEditSuccess: (stoppage: StoppageEvent) => void
  plantId: string
  onClose(): void
}

export const StoppageAnalysisSideCard: FC<StoppageAnalysisSideCardProps> = ({
  stoppage,
  stoppageId,
  onEdit,
  onDelete,
  isEditMode,
  onEditSuccess,
  plantId,
  onClose
}) => {
  const {data: item} = useStoppage(plantId, stoppageId, {
    enabled: !stoppage
  })

  const stoppageToPreview = stoppage || item

  if (stoppageToPreview) {
    return !isEditMode ? (
      <StoppageDetails
        onDelete={() => onDelete(stoppageToPreview)}
        onEdit={() => onEdit(true)}
        stoppage={stoppageToPreview}
        onClose={onClose}
      />
    ) : (
      <StoppageEdit
        stoppage={stoppageToPreview}
        plantId={plantId}
        doClose={() => onEdit(false)}
        onSuccess={onEditSuccess}
      />
    )
  }

  return (
    <ContentBox
      data-test-id="sideCard-spinner"
      isLoading
      minHeight="450px"
      mode="max100PercentOfParentHeight"
    />
  )
}
