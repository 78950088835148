import {StoppageKind} from '@hconnect/common/types'
import {formatNumber} from '@hconnect/common/utils'
import {CardBox} from '@hconnect/uikit/src/lib2'
import {Box} from '@mui/material'
import {isEmpty, orderBy} from 'lodash'
import React, {FC, useCallback, useMemo} from 'react'
import {useTranslation} from 'react-i18next'

import {getBarTimeFormattedLabel, getTimeStep, periodTimeDataIsEmpty} from '../../helpers'
import {useAggregatedStoppageStatistics} from '../../hooks/useAggregatedStoppageStatistics'
import {usePlantStoppages} from '../../hooks/usePlantStoppages'
import {useTimeRange} from '../../hooks/useTimeRange'
import {useTranslationPrefix} from '../../hooks/useTranslationPrefix'
import {StoppageCode} from '../../types'
import {ComboChart, ComboChartData} from '../charts'
import {DataContentWrapper} from '../DataContentWrapper'

type TopIssuesByCodeProps = {
  plantId: string
  stoppageConfig?: StoppageCode[]
  selectedEquipments?: string[]
  selectedEquipmentNumbers?: string[]
  selectedStoppages?: StoppageKind[]
  selectedStoppageCodes?: string[]
  title: string
  issueCount: number
  hideInfoIcon?: boolean
  showFramesLabelFromTimeRange?: boolean
}

export const TopIssuesByCode: FC<TopIssuesByCodeProps> = ({
  plantId,
  stoppageConfig: StoppageConfigProp,
  selectedEquipments,
  selectedEquipmentNumbers,
  selectedStoppages,
  selectedStoppageCodes
}) => {
  const {
    t,
    i18n: {language}
  } = useTranslation()
  const {performancePrefix} = useTranslationPrefix()
  const timeRange = useTimeRange()
  const [from, to] = [timeRange.from, timeRange.to]

  const timeStep = getTimeStep(timeRange)

  const timePeriodStatisticsQuery = useAggregatedStoppageStatistics(plantId, {
    from,
    to,
    ...(selectedEquipments && {mainEquipmentNumbers: selectedEquipments}),
    ...(selectedEquipmentNumbers && {equipmentNumbers: selectedEquipmentNumbers}),
    ...(selectedStoppages && {kinds: selectedStoppages}),
    ...(selectedStoppageCodes && {stoppageCodes: selectedStoppageCodes}),
    timeStep
  })
  const stoppageConfigQuery = usePlantStoppages(plantId, {enabled: !StoppageConfigProp})
  const stoppageConfig = StoppageConfigProp || stoppageConfigQuery.data

  const barLabel = t(`${performancePrefix}.downtime.label.hours`)
  const lineLabel = t(`${performancePrefix}.downtime.label.noStoppages`)

  const chartData = useMemo<ComboChartData<string> | undefined>(() => {
    if (timePeriodStatisticsQuery.data?.some((entry) => entry.stoppagesCount > 0)) {
      const sortedData = orderBy(timePeriodStatisticsQuery.data, 'timeStepStart')
      const barData: number[] = []
      const lineData: number[] = []
      sortedData.forEach((entry) => {
        const barValue = entry.hoursStopped
        const lineValue = Math.ceil(entry.stoppagesCount)
        barData.push(barValue)
        lineData.push(lineValue)
      })

      const columns: string[] = sortedData.map((entry) =>
        getBarTimeFormattedLabel(timeStep, entry.timeStepStart, language)
      )
      return {
        columns,
        barData,
        lineData,
        barLabel,
        lineLabel,
        dataTestId: 'top-issues-by-code-chart'
      }
    }
  }, [timePeriodStatisticsQuery.data, barLabel, lineLabel, timeStep, language])

  const retry = useCallback(() => {
    if (timePeriodStatisticsQuery.error) {
      void timePeriodStatisticsQuery.refetch()
    }
    if (stoppageConfigQuery.error) {
      void stoppageConfigQuery.refetch()
    }
  }, [timePeriodStatisticsQuery, stoppageConfigQuery])

  const getStoppageTitle = useCallback(
    (column) => {
      const stoppageCode = stoppageConfig?.find(({code}) => column.includes(code))
      return stoppageCode ? stoppageCode.code : ''
    },
    [stoppageConfig]
  )
  const getStoppageDescription = useCallback(
    (column) => {
      const stoppageCode = stoppageConfig?.find(({code}) => column.includes(code))
      return stoppageCode ? stoppageCode.text : ''
    },
    [stoppageConfig]
  )

  const getStoppageLabel = useCallback(
    (dataIndex: number, rawValue: number) => {
      if (dataIndex === 0) {
        return `${lineLabel}: ${formatNumber(rawValue, language)}`
      }
      return `${barLabel}: ${formatNumber(rawValue, language)}`
    },
    [barLabel, language, lineLabel]
  )

  const renderContent = () =>
    chartData && (
      <ComboChart
        getTitle={getStoppageTitle}
        getAfterTitle={getStoppageDescription}
        getStoppageLabel={getStoppageLabel}
        data={chartData}
      />
    )

  return (
    <CardBox data-test-id="top-issues-by-code">
      <Box height={280}>
        <DataContentWrapper
          isLoading={timePeriodStatisticsQuery.isLoading || stoppageConfigQuery.isLoading}
          data={[timePeriodStatisticsQuery.data, stoppageConfig]}
          isEmpty={
            periodTimeDataIsEmpty(timePeriodStatisticsQuery?.data) || isEmpty(stoppageConfig)
          }
          retryFunction={retry}
          error={timePeriodStatisticsQuery.error || stoppageConfigQuery.error}
          renderContent={renderContent}
          progressSx={{color: 'primary.main'}}
        />
      </Box>
    </CardBox>
  )
}
