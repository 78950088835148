import {useState} from 'react'
import {useLocation} from 'react-router'

import {FAILURE_STOPPAGE} from '../consts'
import {getEquipmentsFromUrl} from '../helpers'
import {EquipmentData, IssueGroupingKey} from '../types'

import {useQueryParamValue} from './useQueryParamValue'

export const useDowntimeStoppageDetailsStates = ({
  groupType,
  groupId
}: {
  groupType: IssueGroupingKey
  groupId: string
}): {
  failureStoppage?: string
  setFailureStoppage: (value?: string) => void
  mainEquipments?: EquipmentData[]
  setMainEquipments: (value?: EquipmentData[]) => void
  equipmentNumbers?: EquipmentData[]
  setEquipmentNumbers: (value?: EquipmentData[]) => void
  selectedStoppageCodes?: string[]
  setSelectedStoppageCodes: (value?: string[]) => void
} => {
  const {search} = useLocation()
  const searchParams = new URLSearchParams(search)

  const [failureStoppage, setFailureStoppage] = useQueryParamValue<string>(FAILURE_STOPPAGE, {
    keepInLocalStorage: true
  })
  const [mainEquipments, setMainEquipments] = useState<EquipmentData[] | undefined>(
    getEquipmentsFromUrl(
      groupType === IssueGroupingKey.MAIN_EQUIPMENT
        ? groupId
        : searchParams.get('mainEquipmentNumbers')
    )
  )

  const [equipmentNumbers, setEquipmentNumbers] = useState<EquipmentData[] | undefined>(
    getEquipmentsFromUrl(
      groupType === IssueGroupingKey.EQUIPMENT_NUMBER
        ? groupId
        : searchParams.get('equipmentNumbers')
    )
  )

  const [selectedStoppageCodes, setSelectedStoppageCodes] = useState<string[] | undefined>(
    groupType === IssueGroupingKey.STOPPAGE_CODE
      ? [groupId]
      : searchParams.get('stoppageCodes')?.split(',')
  )

  return {
    failureStoppage,
    setFailureStoppage,
    mainEquipments,
    setMainEquipments,
    equipmentNumbers,
    setEquipmentNumbers,
    selectedStoppageCodes,
    setSelectedStoppageCodes
  }
}
