import {getRunningTimeData} from '@hconnect/common/components/runningTimes'
import {useSorting} from '@hconnect/common/hooks/useSorting'
import {
  SortDirection,
  TableContainer,
  SxProps,
  Theme,
  TableCell,
  TableSortLabel,
  TableRow,
  TableHead,
  Table,
  TableBody,
  Typography
} from '@mui/material'
import {orderBy} from 'lodash'
import moment from 'moment-timezone'
import React from 'react'
import {useTranslation} from 'react-i18next'

import {useTranslationPrefix} from '../../hooks/useTranslationPrefix'
import {RunningTimesOffPeriodsWithoutDetail} from '../../types'

const STOPPAGE_LIST_COLUMN_IDS = ['equipment', 'duration']
type StoppageTableColumnKey = (typeof STOPPAGE_LIST_COLUMN_IDS)[number]

interface StoppageTableHeaderCellProps {
  columnKey: StoppageTableColumnKey
  orderByKey?: StoppageTableColumnKey
  sortDir: SortDirection
  onSortClick: (key: StoppageTableColumnKey) => void
  sx?: SxProps<Theme>
  children?: React.ReactNode
}

const StoppageTableHeaderCell = ({
  columnKey,
  orderByKey,
  sortDir,
  onSortClick,
  children,
  sx
}: StoppageTableHeaderCellProps) => (
  <TableCell sortDirection={orderByKey === columnKey && sortDir} sx={sx}>
    <TableSortLabel
      active={sortDir && orderByKey === columnKey}
      direction={orderByKey === columnKey && sortDir ? sortDir : undefined}
      onClick={() => onSortClick(columnKey)}
    >
      {children}
    </TableSortLabel>
  </TableCell>
)

const sortStoppages = (
  stoppages: RunningTimesOffPeriodsWithoutDetail[],
  orderByKey: StoppageTableColumnKey,
  sortDir: SortDirection
): RunningTimesOffPeriodsWithoutDetail[] => {
  if (orderByKey === 'duration') {
    stoppages.sort(
      (a: RunningTimesOffPeriodsWithoutDetail, b: RunningTimesOffPeriodsWithoutDetail) => {
        if (
          moment(a.runningTime.endTechnical).diff(moment(a.runningTime.beginTechnical)) <
          moment(b.runningTime.endTechnical).diff(moment(b.runningTime.beginTechnical))
        ) {
          return sortDir === 'asc' ? -1 : 1
        }

        if (
          moment(a.runningTime.endTechnical).diff(moment(a.runningTime.beginTechnical)) >
          moment(b.runningTime.endTechnical).diff(moment(b.runningTime.beginTechnical))
        ) {
          return sortDir === 'asc' ? 1 : -1
        }
        return 0
      }
    )
    return stoppages
  }
  if (orderByKey === 'equipment') {
    return orderBy(stoppages, ['equipmentName', 'equipmentId'], [sortDir])
  }

  const values = orderBy(stoppages, [orderByKey], [sortDir])
  return values
}

type StoppageListNavigatorTableProps = {
  stoppages: RunningTimesOffPeriodsWithoutDetail[]
  setSelectedStoppage: (stoppage: RunningTimesOffPeriodsWithoutDetail) => void
}

export const StoppageListNavigatorTable: React.FC<StoppageListNavigatorTableProps> = ({
  stoppages,
  setSelectedStoppage
}) => {
  const {
    t,
    i18n: {language}
  } = useTranslation()
  const {performancePrefix} = useTranslationPrefix()

  const {sortedList, onSortClick, orderByKey, sortDir} = useSorting<
    RunningTimesOffPeriodsWithoutDetail,
    StoppageTableColumnKey
  >({
    initialSortDir: false,
    initialOrderByKey: 'createdAt',
    data: stoppages,
    sort: sortStoppages
  })

  return (
    <TableContainer data-test-id="plants-table-container">
      <Table>
        <TableHead>
          <TableRow>
            <StoppageTableHeaderCell
              columnKey={'equipment'}
              orderByKey={orderByKey}
              sortDir={sortDir}
              onSortClick={onSortClick}
            >
              {t(`${performancePrefix}.newStoppagesBanner.label.equipment`)}
            </StoppageTableHeaderCell>
            <StoppageTableHeaderCell
              columnKey={'duration'}
              orderByKey={orderByKey}
              sortDir={sortDir}
              onSortClick={onSortClick}
            >
              {t(`${performancePrefix}.newStoppagesBanner.label.duration`)}
            </StoppageTableHeaderCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {sortedList.map((stoppage) => (
            <TableRow
              key={`${stoppage.equipment.id}-${stoppage.runningTime.beginTechnical}-${stoppage.runningTime.endTechnical}`}
              onClick={() => setSelectedStoppage(stoppage)}
            >
              <TableCell>
                <div style={{display: 'block'}}>
                  <Typography variant="subtitle1">
                    {stoppage.equipment.text || stoppage.equipment.id}
                  </Typography>
                  <Typography variant="caption" color="secondary">
                    {getRunningTimeData(stoppage.runningTime, language, t)}
                  </Typography>
                </div>
              </TableCell>
              <TableCell>
                {`${moment
                  .duration(
                    moment(stoppage.runningTime.endTechnical).diff(
                      moment(stoppage.runningTime.beginTechnical)
                    )
                  )
                  .asHours()
                  .toFixed(2)} ${t(`${performancePrefix}.newStoppagesBanner.label.hours`)}`}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}
