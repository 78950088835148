import {StoppageKind} from '@hconnect/common/types'
import {DateRange} from '@hconnect/uikit'
import {AxiosError} from 'axios'
import {useQuery, UseQueryOptions} from 'react-query'
import {useNavigate} from 'react-router-dom'

import {handleError} from '../../shared/helpers/errorHandling'
import {useAxios} from '../../shared/hooks/useApi'
import {getTimezoneOffset, prepareAggregatedStoppageCodePayload} from '../helpers'
import {AggregatedStoppageStatisticsResponse, TimeStep} from '../types'

const QueryKey = 'aggregated-stoppage-statistics'

export const useAggregatedStoppageStatistics = (
  plantId: string,
  payload: DateRange<Date> & {
    mainEquipmentNumbers?: string[]
    equipmentNumbers?: string[]
    kinds?: StoppageKind[]
    stoppageCodes?: string[]
    timeStep: TimeStep
  },
  options?: UseQueryOptions<AggregatedStoppageStatisticsResponse, AxiosError>
) => {
  const axiosInstance = useAxios()
  const navigate = useNavigate()

  return useQuery<AggregatedStoppageStatisticsResponse, AxiosError>(
    [QueryKey, plantId, payload],
    async () => {
      const path = `/downtime-dashboard/${plantId}/stoppages/time-period-statistics`
      const response = await axiosInstance.get<AggregatedStoppageStatisticsResponse>(path, {
        params: prepareAggregatedStoppageCodePayload({
          timezoneOffset: getTimezoneOffset(),
          to: payload.to,
          from: payload.from,
          mainEquipmentNumbers: payload.mainEquipmentNumbers,
          equipmentNumbers: payload.equipmentNumbers,
          kinds: payload.kinds,
          stoppageCodes: payload.stoppageCodes,
          timeStep: payload.timeStep
        })
      })

      return response.data
    },
    {
      staleTime: 1000 * 60 * 15, // 15 mins
      onError: (err) => handleError(err, navigate),
      ...options
    }
  )
}
