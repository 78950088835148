import {Permission} from '@hconnect/apiclient'
import {Route} from 'react-router-dom'

import {PerformancePlantSelect} from '../performanceDashboard/pages/PerformancePlantSelect'
import {TranslationPrefixProvider} from '../shared/hooks/useTranslationPrefix'
import {Context} from '../shared/types/temp.types'

import {GlobalContextProvider} from './hooks/useGlobalContext'
import {ChecklistsTemplatesPage} from './pages'
import {CHECKLISTS, PLANT_CHECKLISTS_TEMPLATES} from './routing/routes'
import {DefaultShell} from './templates'
import {hasChecklistsPermission} from './utils/permissions'

const Wrapper: React.FC<{
  context: Context
  permissions: Permission[]
  children: React.ReactNode
}> = ({children, context, permissions}) => (
  <TranslationPrefixProvider>
    <GlobalContextProvider initialState={{permissions}}>
      <DefaultShell context={context}>{children}</DefaultShell>
    </GlobalContextProvider>
  </TranslationPrefixProvider>
)

export const getChecklistsRoutes = ({
  context,
  permissions
}: {
  context: Context
  permissions: Permission[]
}) => {
  if (!hasChecklistsPermission(permissions)) {
    return []
  }

  return [
    <Route
      key="checklists-plant-select"
      path={CHECKLISTS}
      element={
        <Wrapper context={context} permissions={permissions}>
          <PerformancePlantSelect path={PLANT_CHECKLISTS_TEMPLATES} />
        </Wrapper>
      }
    />,
    <Route
      path={PLANT_CHECKLISTS_TEMPLATES}
      key="checklists-templates"
      element={
        <Wrapper context={context} permissions={permissions}>
          <ChecklistsTemplatesPage />
        </Wrapper>
      }
    />
  ]
}
