import {AxiosError} from 'axios'
import {useQuery, UseQueryOptions} from 'react-query'
import {useNavigate} from 'react-router-dom'

import {handleError} from '../../shared/helpers/errorHandling'
import {useAxios} from '../../shared/hooks/useApi'
import {getTimezoneOffset} from '../helpers'
import {AggregatedStoppage, AggregatedStoppagesPayload} from '../types'

const QueryKey = 'aggregated-stoppages'

export const useAggregatedStoppages = (
  plantId: string,
  payload: AggregatedStoppagesPayload,
  options?: UseQueryOptions<AggregatedStoppage[], AxiosError>
) => {
  const axiosInstance = useAxios()
  const navigate = useNavigate()

  return useQuery<AggregatedStoppage[], AxiosError>(
    [QueryKey, plantId, payload],
    async () => {
      const path = `/downtime-dashboard/${plantId}/aggregated-stoppages`
      const response = await axiosInstance.post<AggregatedStoppage[]>(path, {
        ...payload,
        timezoneOffset: getTimezoneOffset()
      })

      return response.data
    },
    {
      staleTime: 1000 * 60 * 15, // 15 mins
      onError: (err) => handleError(err, navigate),
      ...options
    }
  )
}
