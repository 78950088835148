import {EventsType, ViewMode} from '@hconnect/common/components/eventsList/types'
import {
  noSideCard,
  sideCardSize,
  tableWithOutSideCard,
  tableWithSideCard,
  withSideCard
} from '@hconnect/common/consts'
import {ArrowKey, scrollToEvent, useArrowKeys} from '@hconnect/common/hooks/useArrowKeys'
import {useWidth} from '@hconnect/common/hooks/useWidth'
import {modeAfterClose} from '@hconnect/common/utils/cardDetailsHelpers'
import {getTranslationKey} from '@hconnect/common/utils/translation.utils'
import {PaginationOptions} from '@hconnect/uikit'
import {CardBox, DataTable, IconWithTooltipButton, MarkdownText} from '@hconnect/uikit/src/lib2'
import ListIcon from '@mui/icons-material/List'
import {
  Box,
  CircularProgress,
  FormControl,
  FormControlLabel,
  Grid,
  Switch,
  Theme,
  Typography
} from '@mui/material'
import React, {useCallback, useState} from 'react'
import {useTranslation} from 'react-i18next'

import {HProduceTask} from '../types/task.types'

import {SideCard} from './SideCard'
import {eventRowSx} from './styles'

type SimpleEventsTableProps = {
  events: HProduceTask[]
  paginationOptions?: PaginationOptions
  columns: any
  translationPrefix?: string
  isLoading: boolean
  selectedItemId?: string
  showDescriptions?: boolean
  onRowClick: (clickedItem: HProduceTask, i: number) => void
}

const SimpleEventsTable: React.FC<SimpleEventsTableProps> = (props) => {
  const {t} = useTranslation()
  return (
    <Box
      data-test-id="events-table"
      display="flex"
      flexDirection="column"
      flex={1}
      overflow="hidden"
      justifyContent="space-between"
    >
      <DataTable<HProduceTask>
        columns={props.columns}
        data={props.events || []}
        rowSx={(item) => eventRowSx(props.selectedItemId === item.id)}
        emptyMessage={
          (!props.isLoading &&
            t(getTranslationKey('shiftEvent.noData', props.translationPrefix))) ??
          ''
        }
        onRowClick={(keyEvent: React.MouseEvent, clickedItem: HProduceTask, i: number) =>
          props.onRowClick(clickedItem, i)
        }
        paginationOptions={props.paginationOptions}
        data-test-id="events-table-container"
        rowFooterTemplate={
          props.showDescriptions
            ? ({rowData}) => (
                <Typography
                  component="div"
                  sx={{
                    display: '-webkit-box',
                    WebkitLineClamp: 3,
                    WebkitBoxOrient: 'vertical',
                    overflow: 'hidden'
                  }}
                  data-test-id={`events-table-${rowData.id}-description`}
                >
                  {rowData.description && <MarkdownText>{rowData.description}</MarkdownText>}
                </Typography>
              )
            : undefined
        }
      />
    </Box>
  )
}

const getProgressSpinner = (showBottomProgress?: boolean) =>
  showBottomProgress && (
    <CircularProgress
      sx={{marginX: 'auto', marginTop: 2}}
      data-test-id="events-view-bottom-progress"
    />
  )

type Props = {
  events: HProduceTask[]
  isLoading: boolean
  viewMode: ViewMode
  setViewMode: (mode: ViewMode) => void
  paginationOptions?: PaginationOptions
  columnsMap: any
  timezone: string
  translationPrefix?: string
  showBottomProgress?: boolean
}

export const EventsTableWrapper: React.FC<Props> = (props) => {
  const {
    events,
    isLoading,
    setViewMode,
    paginationOptions,
    columnsMap,
    viewMode,
    translationPrefix
  } = props

  const {t} = useTranslation()
  const [showDescriptions, setShowDescriptions] = useState<boolean>(false)
  const [selectedTask, setSelectedTask] = useState<HProduceTask | undefined>(
    viewMode.mode === 'detailsMode' ? events.find(({id}) => id === viewMode.itemId) : undefined
  )

  const breakPoint = useWidth()

  const isSideCardShown = viewMode.mode !== 'tableOnlyMode'
  const isSmall = breakPoint === 'xs' || breakPoint === 'sm'
  const isTableShown = !isSideCardShown || !isSmall

  const onRowClicked = useCallback(
    (clickedItem: HProduceTask) => {
      setViewMode({mode: 'detailsMode', itemId: clickedItem.id})
      setSelectedTask(clickedItem)
    },
    [setViewMode]
  )

  const selectedItem =
    selectedTask ||
    (viewMode.mode === 'detailsMode' || viewMode.mode === 'editMode'
      ? events.find(({id}) => id === viewMode.itemId)
      : undefined)

  const arrowKeyHandler = useCallback(
    (arrow: ArrowKey) => {
      if (!events || !events.length) {
        return
      }

      const currentPosition = !selectedItem?.id
        ? -1
        : events.findIndex((e) => e.id === selectedItem?.id)
      let newPosition = -1
      if (arrow === 'up') {
        if (currentPosition === -1) {
          newPosition = events.length - 1
        } else {
          newPosition = (currentPosition - 1 + events.length) % events.length
        }
      }
      if (arrow === 'down') {
        if (currentPosition === -1) {
          newPosition = 0
        } else {
          newPosition = (currentPosition + 1) % events.length
        }
      }
      scrollToEvent(arrow, newPosition)

      const newEventToBeSelected = events[newPosition]
      if (newEventToBeSelected) {
        onRowClicked(newEventToBeSelected)
      }
    },
    [events, selectedItem?.id, onRowClicked]
  )

  useArrowKeys(arrowKeyHandler)

  const tableSize = (isSideCardShown ? withSideCard : noSideCard).get(breakPoint) ?? 'S'

  const doClose = (original?: EventsType, editVersion?: EventsType) =>
    setViewMode(modeAfterClose<EventsType>(original, editVersion))

  return (
    <Grid container flexGrow={1} overflow={'hidden'} spacing={2}>
      {isTableShown && (
        <Grid
          item
          height={'100%'}
          {...(isSideCardShown ? tableWithSideCard : tableWithOutSideCard)}
        >
          <CardBox
            sx={(theme: Theme) => ({
              display: 'flex',
              flexDirection: 'column',
              height: '100%',
              [theme.breakpoints.between('md', 'xl')]: {
                zoom: 0.8
              }
            })}
          >
            {isSmall ? (
              <Box display="flex" justifyContent="space-around" mb={2}>
                <FormControl>
                  <IconWithTooltipButton
                    data-test-id="events-show-descriptions-toggle"
                    onClick={() => setShowDescriptions(!showDescriptions)}
                    icon={<ListIcon />}
                    title={t(
                      getTranslationKey('eventsTable.action.toggleDescriptions', translationPrefix)
                    )}
                    checked={showDescriptions}
                  />
                </FormControl>
              </Box>
            ) : (
              <Box display={'flex'} justifyContent={'right'} mb={1}>
                <Box
                  display={'flex'}
                  flexDirection={{xs: 'column', sm: 'row'}}
                  justifyContent={'flex-end'}
                >
                  <FormControlLabel
                    control={
                      <Switch
                        checked={showDescriptions}
                        data-test-id="events-show-descriptions-toggle"
                        onChange={() => setShowDescriptions(!showDescriptions)}
                        color="primary"
                      />
                    }
                    label={t(
                      getTranslationKey('eventsTable.action.toggleDescriptions', translationPrefix)
                    )}
                  />
                </Box>
              </Box>
            )}

            <SimpleEventsTable
              events={events}
              onRowClick={onRowClicked}
              paginationOptions={paginationOptions}
              columns={columnsMap[tableSize]}
              selectedItemId={selectedItem?.id}
              isLoading={isLoading}
              showDescriptions={showDescriptions}
              translationPrefix={translationPrefix}
            />
            {getProgressSpinner(props.showBottomProgress)}
          </CardBox>
        </Grid>
      )}
      {isSideCardShown && (viewMode.mode as string) !== 'tableOnlyMode' && (
        <Grid
          item
          {...sideCardSize}
          sx={{
            height: '100%',
            overflow: 'hidden'
          }}
        >
          <SideCard
            {...viewMode}
            task={selectedItem}
            setMode={setViewMode}
            doClose={doClose}
            timezone={props.timezone}
            translationPrefix={translationPrefix}
          />
        </Grid>
      )}
    </Grid>
  )
}
