import {Iso8601} from '@hconnect/common/types'
import {DateRange, timeFormatter} from '@hconnect/uikit/src/common'
import moment, {Moment} from 'moment-timezone'
import {getI18n} from 'react-i18next'

import {AggregatedStoppageStatisticsResponse, TimeStep, TimeStepForTitle} from '../types'

export const getTimeStep = (timeRange: DateRange<Date>) => {
  const diffInHours = moment(timeRange.to).diff(timeRange.from, 'hours', true)
  const diffInDays = moment(timeRange.to).diff(timeRange.from, 'days', true)
  const diffInMonths = moment(timeRange.to).diff(timeRange.from, 'months', true)

  if (diffInHours <= 24) {
    return TimeStep.HOUR
  }
  if (diffInDays <= 7) {
    return TimeStep.DAY
  }
  if (diffInDays > 7 && diffInMonths < 2) {
    return TimeStep.WEEK
  }
  return TimeStep.MONTH
}

const isStepMonth = (
  isSameYear: boolean,
  isFirstDayOfMonth: boolean,
  isLastDayOfMonth: boolean,
  isFirstDayOfYear: boolean,
  isLastDayOfYear: boolean
) => isSameYear && isFirstDayOfMonth && isLastDayOfMonth && !(isFirstDayOfYear && isLastDayOfYear)
export const getTimeStepForTitle = (timeRange: DateRange<Date>) => {
  const originalLocale = moment.locale()
  // using DE locale to set Monday as a first day of a week
  moment.locale('de')
  const momentFrom = moment(timeRange.from)
  const momentTo = moment(timeRange.to)

  const isSameHour = moment(timeRange.from).isSame(timeRange.to, 'hour')
  const isSameDay = moment(timeRange.from).isSame(timeRange.to, 'day')
  const isSameWeek = moment(timeRange.from).isSame(timeRange.to, 'week')
  const isSameMonth = moment(timeRange.from).isSame(timeRange.to, 'month')
  const isSameYear = moment(timeRange.from).isSame(timeRange.to, 'year')

  const startOfWeek = moment(timeRange.from).startOf('week')
  const startOfMonth = moment(timeRange.from).startOf('month')
  const startOfYear = moment(timeRange.from).startOf('year')
  const endOfWeek = moment(timeRange.to).endOf('week')
  const endOfMonth = moment(timeRange.to).endOf('month')
  const endOfYear = moment(timeRange.to).endOf('year')

  const isFirstDayOfMonth = momentFrom.isSame(startOfMonth, 'day')
  const isFirstDayOfWeek = momentFrom.isSame(startOfWeek, 'day')
  const isLastDayOfWeek = momentTo.isSame(endOfWeek, 'day')
  const isLastDayOfMonth = momentTo.isSame(endOfMonth, 'day')
  const isFirstDayOfYear = momentFrom.isSame(startOfYear, 'day')
  const isLastDayOfYear = momentTo.isSame(endOfYear, 'day')
  moment.locale(originalLocale)

  if (isSameHour) {
    return TimeStepForTitle.HOUR
  }
  if (isSameDay) {
    return TimeStepForTitle.DAY
  }
  if (
    (isSameWeek && isSameYear && isSameMonth) ||
    (isSameMonth && isFirstDayOfWeek && isLastDayOfWeek)
  ) {
    return TimeStepForTitle.WEEK
  }
  if (
    isStepMonth(isSameYear, isFirstDayOfMonth, isLastDayOfMonth, isFirstDayOfYear, isLastDayOfYear)
  ) {
    return TimeStepForTitle.MONTH
  }
  if (isFirstDayOfYear && isLastDayOfYear) {
    return TimeStepForTitle.YEAR
  }
}

export const periodTimeDataIsEmpty = (data: AggregatedStoppageStatisticsResponse | undefined) => {
  return data?.every((entry) => entry.stoppagesCount === 0)
}

export const getBarTimeFormattedLabel = (
  step: TimeStep,
  date: Iso8601,
  language: string,
  to?: Iso8601
) => {
  moment.locale(language)
  const momentDate = moment(date)
  const days = moment.weekdays()
  const months = moment.months()
  const day = momentDate.day()
  const month = momentDate.month()
  const year = momentDate.year()
  const week = momentDate.isoWeek()
  const monthYear = `${months[month]} ${year}`

  moment.locale('de')
  const momentFrom = moment(date)
  const momentTo = moment(to)

  const isSameWeek = momentFrom.isSame(momentTo, 'week')
  const isSameMonth = momentFrom.isSame(momentTo, 'month')
  const isSameYear = momentFrom.isSame(momentTo, 'year')

  const dayTo = momentTo.day()
  const weekTo = momentTo.isoWeek()
  const monthTo = momentTo.month()

  const isSevenDays = momentTo.diff(momentFrom, 'days') === 6
  moment.locale(language)

  switch (step) {
    case TimeStep.HOUR: {
      return timeFormatter(momentDate, language)
    }
    case TimeStep.DAY: {
      return days[day]
    }
    case TimeStep.MONTH: {
      return to && isSameYear && !isSameMonth
        ? `${months[month]} - ${months[monthTo]} ${year}`
        : monthYear
    }
    case TimeStep.WEEK: {
      if (isSameWeek && isSameMonth && to && !isSevenDays) {
        return `${days[day]} - ${days[dayTo]}`
      }
      return to && isSameMonth && !isSameWeek
        ? `${week} - ${weekTo} ${getI18n().t('performanceDashboard.recurringDialog.week_plural')}`
        : `${week} ${getI18n().t('performanceDashboard.recurringDialog.week')}`
    }
  }
}

const TIME_STEP_MAP: Record<TimeStepForTitle, TimeStep | null> = {
  [TimeStepForTitle.HOUR]: TimeStep.HOUR,
  [TimeStepForTitle.DAY]: TimeStep.DAY,
  [TimeStepForTitle.WEEK]: TimeStep.WEEK,
  [TimeStepForTitle.MONTH]: TimeStep.MONTH,
  [TimeStepForTitle.YEAR]: null
}
const getDatePeriodLabel = (from: Moment, to: Moment) => {
  const singleDate = from.isSame(to)
  return `${from.format('LL')}${singleDate ? '' : ` - ${to.format('LL')}`}`
}

export const getBarTimeFormattedLabelTitle = (
  step: TimeStepForTitle | undefined,
  from: Iso8601,
  to: Iso8601,
  language: string
) => {
  if (!step) {
    return getDatePeriodLabel(moment(from), moment(to))
  }
  // using DE locale to set Monday as a first day of a week
  moment.locale('de')
  const momentDate = moment(from)
  const momentTo = moment(to)

  const startOfWeek = moment(from).clone().startOf('week')
  const startOfMonth = moment(from).clone().startOf('month')
  const startOfYear = moment(from).clone().startOf('year')
  const endOfWeek = momentTo.clone().endOf('week')
  const endOfMonth = momentTo.clone().endOf('month')
  const endOfYear = momentTo.clone().endOf('year')

  const isFirstDayOfYear = momentDate.isSame(startOfYear, 'day')
  const isFirstDayOfMonth = momentDate.isSame(startOfMonth, 'day')
  const isFirstDayOfWeek = momentDate.isSame(startOfWeek, 'day')
  const isLastDayOfWeek = momentTo.isSame(endOfWeek, 'day')
  const isLastDayOfMonth = momentTo.isSame(endOfMonth, 'day')
  const isLastDayOfYear = momentTo.isSame(endOfYear, 'day')

  const isSingleDate = momentDate.isSame(momentTo, 'day')
  const isSingleWeek = momentDate.isSame(momentTo, 'week')
  moment.locale(language)

  if (
    !(
      (isFirstDayOfYear || isFirstDayOfMonth || isFirstDayOfWeek) &&
      (isLastDayOfYear || isLastDayOfMonth || isLastDayOfWeek)
    ) &&
    !isSingleDate &&
    !isSingleWeek
  ) {
    return getDatePeriodLabel(moment(from), moment(to))
  }

  if (step === TimeStepForTitle.YEAR) {
    const year = momentDate.year()
    const isSameYear = momentDate.isSame(momentTo, 'year')
    return isSameYear ? String(year) : `${year} - ${momentTo.year()}`
  }
  const stepForBarLabel = TIME_STEP_MAP[step]
  if (stepForBarLabel) {
    return getBarTimeFormattedLabel(stepForBarLabel, from, language, to)
  }
}

export const calculateEndDate = (timeStep: TimeStep, startDateTime: Date): Date => {
  switch (timeStep) {
    case TimeStep.HOUR:
      return moment(startDateTime).add(1, 'hour').subtract(1, 'millisecond').toDate()
    case TimeStep.DAY:
      return moment(startDateTime).endOf('day').toDate()
    case TimeStep.WEEK:
      return moment(startDateTime).endOf('week').toDate()
    case TimeStep.MONTH:
      return moment(startDateTime).endOf('month').toDate()
    default:
      throw new Error('Invalid timeStep')
  }
}
export const getTimezoneOffset = () => moment().utcOffset() / 60
