import {dateFormatter} from '@hconnect/uikit'
import {CardBox} from '@hconnect/uikit/src/lib2'
import {Grid, Stack, Typography} from '@mui/material'
import moment from 'moment'
import {useTranslation} from 'react-i18next'

import {useTranslationPrefix} from '../../shared/hooks/useTranslationPrefix'
import type {ChecklistTemplate} from '../types'

type Props = {
  data?: ChecklistTemplate[]
}

export const ChecklistTemplatesList = ({data}: Props) => {
  return (
    <Stack spacing={2}>
      {data?.map((checklist) => <ChecklistTemplateListItem key={checklist.id} item={checklist} />)}
    </Stack>
  )
}

const ChecklistTemplateListItem = ({item}: {item: ChecklistTemplate}) => {
  const {checklistsPrefix} = useTranslationPrefix()
  const {t} = useTranslation()

  return (
    <CardBox>
      <Stack spacing={1.5}>
        <Typography variant="h4">{item.name}</Typography>
        <Typography>{item.description}</Typography>
        <Grid container columns={{xs: 2, sm: 4}} rowSpacing={1.5}>
          <GridDataCell
            label={t(`${checklistsPrefix}.label.type`)}
            value={item.type ? t(`${checklistsPrefix}.checklist.templateType.${item.type}`) : '-'}
          />
          <GridDataCell
            label={t(`${checklistsPrefix}.label.category`)}
            value={
              item.category
                ? t(`${checklistsPrefix}.checklist.templateCategory.${item.category}`)
                : '-'
            }
          />
          <GridDataCell
            label={t(`${checklistsPrefix}.label.addedToTheSystem`)}
            value={item.createdAt ? dateFormatter(moment(item.createdAt)) : '-'}
          />
        </Grid>
      </Stack>
    </CardBox>
  )
}

const GridDataCell = ({label, value}: {label: string; value: string}) => (
  <Grid item xs={1} sm={1}>
    <Typography variant="caption" color="text.secondary">
      {label}
    </Typography>
    <Typography>{value}</Typography>
  </Grid>
)
