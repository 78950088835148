import {Box} from '@mui/material'
import React, {FC} from 'react'

type PlaceholderWrapperProps = {
  noBackground?: boolean
  children?: React.ReactNode
}

export const PlaceholderWrapper: FC<PlaceholderWrapperProps> = ({children, noBackground}) => (
  <Box
    sx={{
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flex: 1
    }}
  >
    <Box
      sx={(theme) => ({
        background: noBackground ? 'unset' : theme.palette.common.white,
        color: theme.palette.common.black,
        borderRadius: '10px',
        height: '200px',
        width: '400px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
      })}
    >
      {children}
    </Box>
  </Box>
)
