import {DateRange, TimeZone} from '@hconnect/uikit/src/common'
import {
  timeRangeParamToDateRange,
  QuickSelectionId as DefaultQuickSelectionId
} from '@hconnect/uikit/src/lib2/components/simpleDatePicker/dateRangePickerHelpers'
import {SelectionItem} from '@hconnect/uikit/src/lib2/components/simpleDatePicker/types'
import moment, {Moment} from 'moment-timezone'
import {useTranslation} from 'react-i18next'

import {useTimeRangeParam} from './useTimeRangeParam'
import {getSystemTimezone, usePlantTimezone} from './useTimezone'
import {useTranslationPrefix} from './useTranslationPrefix'

export type JanusQuickSelectionId =
  | 'lastMonth'
  | 'yearToMonthYTM'
  | 'lastYear'
  | 'currentMonth'
  | 'yearToDateYTD'

export type QuickSelectionId = DefaultQuickSelectionId | JanusQuickSelectionId

export const showYTMQuickSelectionIds: QuickSelectionId[] = [
  'yearToMonthYTM',
  'lastYear',
  'yearToDateYTD'
]

export const getQuickSelectionItems = (
  translationFn: (key: string) => string,
  timezone: TimeZone,
  now = new Date(),
  type: 'performance' | 'status' | 'all'
): SelectionItem<QuickSelectionId>[] => {
  const startOfToday: Moment = moment.utc(now).tz(timezone).startOf('day')
  const endOfToday: Moment = startOfToday.clone().endOf('day')
  const startOfYesterday: Moment = startOfToday.clone().subtract(1, 'day')

  const quickSelectItems: SelectionItem<QuickSelectionId>[] = []

  if (type === 'all' || type === 'status') {
    quickSelectItems.push(
      {
        id: 'today',
        title: translationFn('quickSelection.today'),
        dates: [startOfToday.toDate(), endOfToday.toDate()]
      },
      {
        id: 'yesterday',
        title: translationFn('quickSelection.yesterday'),
        dates: [startOfYesterday.toDate(), startOfYesterday.clone().endOf('day').toDate()]
      },
      {
        id: 'last7days',
        title: translationFn('quickSelection.last7days'),
        dates: [startOfToday.clone().subtract(7, 'day').toDate(), endOfToday.toDate()]
      }
    )
  }
  if (type === 'all' || type === 'performance') {
    quickSelectItems.push(
      {
        id: 'currentMonth',
        title: translationFn('quickSelection.currentMonth'),
        dates: [moment().startOf('month').toDate(), endOfToday.toDate()]
      },
      {
        id: 'lastMonth',
        title: translationFn('quickSelection.lastMonth'),
        dates: [
          startOfToday.clone().subtract(1, 'month').startOf('month').toDate(),
          startOfToday.clone().subtract(1, 'month').endOf('month').toDate()
        ]
      }
    )
  }
  // Note: Hide YTM for january. No complete last month -- refer HCP-63381
  if ((type === 'all' || type === 'performance') && moment().month() !== 0) {
    quickSelectItems.push({
      id: 'yearToMonthYTM',
      title: translationFn('quickSelection.yearToMonthYTM'),
      dates: [
        startOfToday.clone().startOf('year').toDate(),
        startOfToday.clone().subtract(1, 'month').endOf('month').toDate()
      ]
    })
  }
  if (type === 'all' || type === 'performance') {
    quickSelectItems.push(
      {
        id: 'last3months',
        title: translationFn('quickSelection.last3months'),
        dates: [
          startOfToday.clone().subtract(3, 'month').startOf('month').toDate(),
          startOfToday.clone().subtract(1, 'month').endOf('month').toDate()
        ]
      },
      {
        id: 'yearToDateYTD',
        title: translationFn('quickSelection.yearToDateYTD'),
        dates: [startOfToday.clone().startOf('year').toDate(), endOfToday.toDate()]
      },
      {
        id: 'lastYear',
        title: translationFn('quickSelection.lastYear'),
        dates: [
          startOfToday.clone().subtract(1, 'year').startOf('year').toDate(),
          startOfToday.clone().subtract(1, 'year').endOf('year').toDate()
        ]
      }
    )
  }

  return quickSelectItems
}

export const usePlantTimeRange = (options: {now?: Date; plantId: string}): DateRange<Date> => {
  const {now = new Date()} = options
  const plantTimezone = usePlantTimezone({plantId: options.plantId})
  return useTimeRange({now, plantTimezone})
}

type UseTimeRange = {
  now?: Date
  plantTimezone?: string
}

export const useTimeRange = (options: UseTimeRange = {}): DateRange<Date> => {
  const {now = new Date(), plantTimezone} = options
  const {t} = useTranslation()
  const {performancePrefix} = useTranslationPrefix()
  const timezone = plantTimezone ?? getSystemTimezone()
  const [timeRange] = useTimeRangeParam()

  const selectionItems = getQuickSelectionItems(
    (key) => t(`${performancePrefix}.${key}`),
    timezone,
    now,
    'all'
  )
  const [start, end, quickSelectionId] = timeRangeParamToDateRange({timeRange, selectionItems})
  return {from: start ?? now, to: end ?? now, quickSelectionId}
}

export const isJanusQuickSelectionId = (
  selectionId: string
): selectionId is JanusQuickSelectionId => {
  return [
    'lastMonth',
    'yearToMonthYTM',
    'lastYear',
    'currentMonth',
    'yearToDateYTD',
    'last3months'
  ].some((el) => el === selectionId)
}

export const showYtmData = (quickSelectionId?: string | null): boolean => {
  return (
    !!quickSelectionId && showYTMQuickSelectionIds.includes(quickSelectionId as QuickSelectionId)
  )
}
