import React from 'react'

type TranslationPrefix = {
  performancePrefix: string
  checklistsPrefix: string
}

const TranslationPrefixContext = React.createContext<TranslationPrefix | undefined>(undefined)

export const TranslationPrefixProvider = ({children}: {children: React.ReactNode}) => {
  const translationPrefix = {
    performancePrefix: 'performanceDashboard',
    checklistsPrefix: 'checklists'
  }
  return (
    <TranslationPrefixContext.Provider value={translationPrefix}>
      {children}
    </TranslationPrefixContext.Provider>
  )
}

// used by consumers
export function useTranslationPrefix() {
  const ctx = React.useContext(TranslationPrefixContext)
  if (ctx === undefined) {
    throw new Error(
      'useTranslationPrefix used outside of TranslationPrefixProvider or translationPrefix is not available'
    )
  } else {
    return ctx
  }
}
