import {AxiosError} from 'axios'
import {useQuery, UseQueryOptions} from 'react-query'
import {useNavigate} from 'react-router-dom'

import {handleError} from '../../../shared/helpers/errorHandling'
import {useAxios} from '../../../shared/hooks/useApi'
import {mapProductionPerformanceValuesDto} from '../../mappers'
import {
  ProductionPerformanceValues,
  ProductionPerformanceValuesResponse,
  TimeRangeWithOffset
} from '../../types'

const QueryKey = 'production-performance-values'

export const useProductionPerformanceValues = (
  {timeRange, locationId, yearOffset = 3}: TimeRangeWithOffset,
  options?: UseQueryOptions<
    ProductionPerformanceValuesResponse,
    AxiosError,
    ProductionPerformanceValues
  >
) => {
  const axiosInstance = useAxios()
  const navigate = useNavigate()

  return useQuery<ProductionPerformanceValuesResponse, AxiosError, ProductionPerformanceValues>(
    [QueryKey, timeRange.startDate, timeRange.endDate, locationId],
    async () => {
      const payload: TimeRangeWithOffset = {
        timeRange,
        yearOffset
      }

      const response = await axiosInstance.post<ProductionPerformanceValuesResponse>(
        '/kpi/dashboard/production-performance-values/',
        payload,
        {params: {locationId}}
      )

      return response.data
    },
    {
      onError: (err) => handleError(err, navigate),
      select: (data) => mapProductionPerformanceValuesDto(data),
      ...options
    }
  )
}
