import {EquipmentCategory} from '@hconnect/common/types'

export const equipmentIdTypes = [
  'technicalId',
  'sapNumber',
  'processId',
  'mainEquipmentCustomId',
  'janusId'
] as const
export type EquipmentIdType = (typeof equipmentIdTypes)[number]

export enum EquipmentType {
  KILN = 'kiln',
  CEMENT_MILL = 'cementMill',
  RAW_MILL = 'rawMill',
  COAL_MILL = 'coalMill',
  CRUSHER = 'crusher',
  ROLLER_PRESS = 'rollerPress',
  VERTICAL_MILL = 'verticalMill',
  BALL_MILL = 'ballMill',
  DOSING_EQUIPMENT = 'dosingEquipment',
  OTHER = 'other'
}

export type EquipmentData = {
  id: string
  matchingId?: string
  text?: string
  type?: EquipmentType
  category?: EquipmentCategory
}
