import {formatNumber} from '@hconnect/common/utils'
import {formatTimeZoneDate, DateRange} from '@hconnect/uikit'
import {DataTable, Column, CardBox} from '@hconnect/uikit/src/lib2'
import CloudOffIcon from '@mui/icons-material/CloudOff'
import {Box, Theme, Typography, useMediaQuery} from '@mui/material'
import {camelCase} from 'lodash'
import React, {useMemo} from 'react'
import {useTranslation} from 'react-i18next'

import {generateKpiData} from '../helpers'
import {useAlternativeFuelRates} from '../hooks/kpi/useAlternativeFuelRates'
import {useKilnCoefficients} from '../hooks/kpi/useKilnCoefficients'
import {useKilnHeatConsumption} from '../hooks/kpi/useKilnHeatConsumption'
import {useMeanTimeBetweenFailures} from '../hooks/kpi/useMeanTimeBetweenFailures'
import {useTechnicalCementPowerConsumption} from '../hooks/kpi/useTechnicalCementPowerConsumption'
import {showYtmData, usePlantTimeRange} from '../hooks/useTimeRange'
import {usePlantTimezone} from '../hooks/useTimezone'
import {useTranslationPrefix} from '../hooks/useTranslationPrefix'
import {
  mapAlternativeFuelRatesDto,
  mapKilnCoefficientsDto,
  mapKilnHeatConsumptionsDto,
  mapMeanTimeBetweenFailuresDto,
  mapTechnicalCementPowerConsumptionDto
} from '../mappers'
import {KpisList, PlantKPIs, TimeRange, ProductionDataType, optionalKPIs} from '../types'

import {DeltaLabel} from './common'
import {ActualKpiTemplates} from './common/ActualPlannedDelta'
import {DataContentWrapper} from './DataContentWrapper'
import {KPI_ICONS_MAP} from './KpiIcons'
import {KpiTableData, PlantKpiMobileGrids} from './PlantKpiMobileGrids'

export const ROW_SX = {
  td: {
    fontSize: 16,
    lineHeight: '40px'
  }
}

type PlantKpiDataProps = {
  plantId: string
  isGrindingUnit?: boolean
  productionData: {
    cementProduction: {data: ProductionDataType}
    clinkerProduction: {data: ProductionDataType}
  }
  showKPIs: boolean | '' | null | undefined
}

export const PlantKpiData: React.FC<PlantKpiDataProps> = ({
  plantId,
  isGrindingUnit = false,
  productionData,
  showKPIs
}) => {
  const {t, i18n} = useTranslation()
  const language = i18n.language
  const timezone = usePlantTimezone({plantId})
  const {performancePrefix} = useTranslationPrefix()
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'))

  const {to, from, quickSelectionId}: DateRange<Date> = usePlantTimeRange({plantId})
  const dateRange: TimeRange = {startDate: from.toJSON(), endDate: to.toJSON()}

  const showYTM = showYtmData(quickSelectionId)
  const isToday = quickSelectionId === 'today'

  const formattedDate = useMemo(
    () => formatTimeZoneDate(to, timezone, isToday ? 'DD/MM/YY' : 'MM/YY', language),
    [language, isToday, timezone, to]
  )

  // Note: KPI endpoints have a "fake" parent wrapper with no data and single descendant
  const {
    data: kilnCoefficientData,
    isLoading: isKilnCoefficientLoading,
    error: kilnCoefficientError
  } = useKilnCoefficients(
    {
      timeRange: dateRange,
      locationId: plantId,
      showYTM
    },
    {
      enabled: !isGrindingUnit,
      select: (data) => mapKilnCoefficientsDto(data)
    }
  )

  const {
    data: technicalCementPowerConsumptionData,
    isLoading: isTechnicalCementPowerConsumptionLoading,
    error: technicalCementPowerConsumptionError
  } = useTechnicalCementPowerConsumption(
    {
      timeRange: dateRange,
      locationId: plantId,
      showYTM
    },
    {select: (data) => mapTechnicalCementPowerConsumptionDto(data)}
  )

  const {
    data: kilnHeatConsumptionsData,
    isLoading: isKilnHeatConsumptionsLoading,
    error: kilnHeatConsumptionsError
  } = useKilnHeatConsumption(
    {
      timeRange: dateRange,
      locationId: plantId,
      showYTM
    },
    {
      enabled: !isGrindingUnit,
      select: (data) => mapKilnHeatConsumptionsDto(data)
    }
  )

  const {
    data: meanTimeBetweenFailuresData,
    isLoading: isMeanTimeBetweenFailuresLoading,
    error: meanTimeBetweenFailuresError
  } = useMeanTimeBetweenFailures(
    {
      timeRange: dateRange,
      locationId: plantId,
      showYTM
    },
    {
      enabled: !isGrindingUnit,
      select: (data) => mapMeanTimeBetweenFailuresDto(data)
    }
  )

  const {
    data: alternativeFuelRatesData,
    isLoading: isAlternativeFuelRatesLoading,
    error: alternativeFuelRatesError
  } = useAlternativeFuelRates(
    {
      timeRange: dateRange,
      locationId: plantId,
      showYTM
    },
    {
      enabled: !isGrindingUnit,
      select: (data) => mapAlternativeFuelRatesDto(data)
    }
  )

  const KpiData: PlantKPIs = useMemo(
    () =>
      generateKpiData(
        plantId,
        {
          alternativeFuelRatesError: !!alternativeFuelRatesError,
          meanTimeBetweenFailuresError: !!meanTimeBetweenFailuresError,
          kilnCoefficientError: !!kilnCoefficientError,
          kilnHeatConsumptionsError: !!kilnHeatConsumptionsError,
          technicalCementPowerConsumptionError: !!technicalCementPowerConsumptionError
        },
        {
          alternativeFuelRatesData,
          meanTimeBetweenFailuresData,
          kilnCoefficientData,
          kilnHeatConsumptionsData,
          technicalCementPowerConsumptionData
        }
      ),
    [
      alternativeFuelRatesData,
      alternativeFuelRatesError,
      kilnCoefficientData,
      kilnCoefficientError,
      kilnHeatConsumptionsData,
      kilnHeatConsumptionsError,
      meanTimeBetweenFailuresData,
      meanTimeBetweenFailuresError,
      plantId,
      technicalCementPowerConsumptionData,
      technicalCementPowerConsumptionError
    ]
  )

  const kpiData = showKPIs ? KpiData : {}
  const AllData = {...productionData, ...kpiData}

  const kpiTableData: KpiTableData[] = Object.keys(AllData)
    .filter((kpi) => {
      if (optionalKPIs.includes(kpi as KpisList)) {
        const actualValue = AllData[kpi].data?.actual
        if (!actualValue) {
          return false
        }
      }

      if (isGrindingUnit)
        return (
          (kpi as KpisList) === KpisList.TechnicalCementPowerCons ||
          (kpi as KpisList) === KpisList.CementProduction
        )
      return true
    })
    .map((kpi) => ({
      name: kpi,
      ...AllData[kpi]
    }))

  const kpisTableConfig = useMemo<Column<KpiTableData>[]>(
    () => [
      {
        key: 'kpi',
        label: t(`${performancePrefix}.plantStatus.label.kpi`),
        customTemplate: ({name, data}) => (
          <Box display="flex" alignItems="center">
            <Box display="flex" mr={1} fontSize={14}>
              {KPI_ICONS_MAP[name]}
            </Box>
            <Typography variant="h5">
              {t(`${performancePrefix}.actualVsPlanned.plantKpis.${camelCase(name as string)}`)}{' '}
              {data?.unit && `(${data.unit})`}
            </Typography>
          </Box>
        )
      },
      {
        key: 'planned',
        label: t(`${performancePrefix}.plantStatus.label.planned`, {date: formattedDate}),
        customTemplate: ({data, isError}) =>
          isError || !data ? (
            <Box
              sx={({palette}) => ({
                display: 'flex',
                alignItems: 'center',
                whiteSpace: 'nowrap',
                fontStyle: 'italic',
                color: palette.text.secondary
              })}
            >
              <CloudOffIcon sx={{m: 1}} />
              <Typography variant="body1">
                {t(`${performancePrefix}.error.label.loadingError`)}
              </Typography>
            </Box>
          ) : (
            formatNumber(data.planned, language)
          )
      },
      {
        key: 'actual',
        label: t(`${performancePrefix}.plantStatus.label.actual`, {date: formattedDate}),
        customTemplate: ({name, data, isError}) => {
          if (!isError && data) {
            const Template = ActualKpiTemplates[name]
            const {actual = 0, planned = 0} = data
            return <Template actual={formatNumber(actual, language)} diff={actual - planned} />
          }

          return <></>
        }
      },
      {
        key: 'delta',
        label: t(`${performancePrefix}.plantStatus.label.delta`),
        customTemplate: ({data, isError}) =>
          !isError && data ? <DeltaLabel value={data.delta} /> : <></>
      }
    ],
    [formattedDate, t, language, performancePrefix]
  )

  if (isMobile) {
    return <PlantKpiMobileGrids kpiTableData={kpiTableData} formattedDate={formattedDate} />
  }

  const renderKPIsContent = () => (
    <DataTable<KpiTableData>
      columns={kpisTableConfig}
      data={kpiTableData || []}
      emptyMessage={t(`${performancePrefix}.plantStatus.label.noData`)}
      rowSx={() => ROW_SX}
      keyExtractor={(item) => item.name}
    />
  )
  return (
    <CardBox px={1.5} pb={0} data-test-id="plant-kpi-data">
      <Typography variant="h3" display="flex" alignItems="center" ml={1.5} mb={3}>
        {t(`${performancePrefix}.plantStatus.label.janusMonthlyKPIs`)}
      </Typography>
      <DataContentWrapper<PlantKPIs>
        isLoading={
          isKilnCoefficientLoading ||
          isTechnicalCementPowerConsumptionLoading ||
          isMeanTimeBetweenFailuresLoading ||
          isAlternativeFuelRatesLoading ||
          isKilnHeatConsumptionsLoading
        }
        data={KpiData}
        error={null}
        renderContent={renderKPIsContent}
        progressSx={{color: 'primary.main'}}
      />
    </CardBox>
  )
}
