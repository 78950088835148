import {OperationHoursLegendTooltip} from '@hconnect/common/components/runningTimes'
import {formatNumber} from '@hconnect/common/utils'
import {CardBox} from '@hconnect/uikit/src/lib2'
import {ExpandMore, ExpandLess} from '@mui/icons-material'
import {Box, Grid, Typography, IconButton, Collapse, Divider} from '@mui/material'
import {isEmpty} from 'lodash'
import React from 'react'
import {useTranslation} from 'react-i18next'
import {generatePath, useNavigate} from 'react-router-dom'

import {getProductionValue} from '../helpers/actualVsTotal.helpers'
import {useActualOrTotalValue} from '../hooks/useActualValue'
import {useTranslationPrefix} from '../hooks/useTranslationPrefix'
import {PLANT_KPI_STATUS, STOPPAGE_ANALYSIS_STATUS} from '../routing'
import {PlantStatus} from '../types'

import {OperationHoursTable} from './OperationHours'

export type PlantSummaryCardProps = {
  plantStatus: PlantStatus
  startDate: string
  endDate: string
  'data-test-id'?: string
}

export const PlantSummaryCard: React.FC<PlantSummaryCardProps> = ({
  plantStatus,
  startDate,
  endDate,
  'data-test-id': dataTestId
}) => {
  const {
    t,
    i18n: {language}
  } = useTranslation()
  const {performancePrefix} = useTranslationPrefix()
  const navigate = useNavigate()
  const [expanded, setExpanded] = React.useState(true)

  const isGrindingUnit =
    !plantStatus.plant.pxTrendMetaData || plantStatus.plant.pxTrendMetaData.isGrindingUnit

  const handleExpandClick = () => {
    setExpanded(!expanded)
  }

  const navigateToPlant = () => {
    navigate(generatePath(PLANT_KPI_STATUS, {plantId: plantStatus.plant.plantId}))
  }

  const handleNavigateToStoppageAnalysis = (equipmentId?: string) => {
    if (equipmentId) {
      navigate(
        generatePath(STOPPAGE_ANALYSIS_STATUS, {
          plantId: plantStatus.plant.plantId,
          equipmentId
        })
      )
    }
  }
  const flags = useActualOrTotalValue()

  const plantClinkerProduction = getProductionValue({
    productionValueFlags: flags,
    actualValue: plantStatus.actualClinkerProduction,
    totalValue: plantStatus.totalClinker
  })
  const plantCementProduction = getProductionValue({
    productionValueFlags: flags,
    actualValue: plantStatus.actualCementProduction,
    totalValue: plantStatus.totalCement
  })

  return (
    <Grid data-test-id={dataTestId}>
      <CardBox>
        <Grid container wrap="nowrap" justifyContent="space-between">
          <Grid item flex="auto">
            <Box display="flex" alignItems="center">
              <IconButton
                onClick={handleExpandClick}
                sx={{marginRight: 1}}
                data-test-id="plant-summary-card-collapse-button"
              >
                {expanded ? <ExpandMore /> : <ExpandLess />}
              </IconButton>

              <Typography variant="h3" sx={{cursor: 'pointer'}} onClick={navigateToPlant}>
                {plantStatus.plant.name}
              </Typography>
              <OperationHoursLegendTooltip translationPrefix={performancePrefix} />
            </Box>
          </Grid>
        </Grid>

        <Collapse in={expanded} timeout="auto" unmountOnExit>
          <Box display="flex" gap={5} my={2}>
            {!isGrindingUnit && (
              <Box display="flex" flexDirection="column">
                <Typography display="flex" alignItems="center">
                  <Typography variant="caption">
                    {t(`${performancePrefix}.plantStatus.label.clinkerProduction`)}
                  </Typography>
                </Typography>
                <Typography sx={{fontSize: '28px', fontWeight: 600, lineHeight: '36px'}}>
                  {plantClinkerProduction
                    ? `${formatNumber(Math.floor(plantClinkerProduction), language)} ${
                        plantStatus.unitClinker
                      }`
                    : '-'}
                </Typography>
              </Box>
            )}

            <Box display="flex" flexDirection="column">
              <Typography display="flex" alignItems="center">
                <Typography variant="caption">
                  {t(`${performancePrefix}.plantStatus.label.cementProduction`)}
                </Typography>
              </Typography>
              <Typography sx={{fontSize: '28px', fontWeight: 600, lineHeight: '36px'}}>
                {plantCementProduction
                  ? `${formatNumber(Math.floor(plantCementProduction), language)} ${
                      plantStatus.unitCement
                    }`
                  : '-'}
              </Typography>
            </Box>
          </Box>

          {!isEmpty(plantStatus?.equipmentRunningTimes) && (
            <>
              <Divider />

              <OperationHoursTable
                noCard
                noChartsSeparator
                startDate={startDate}
                endDate={endDate}
                equipmentRunningTimes={plantStatus?.equipmentRunningTimes}
                onClick={handleNavigateToStoppageAnalysis}
                hideLegend={true}
              />
            </>
          )}
        </Collapse>
      </CardBox>
    </Grid>
  )
}
