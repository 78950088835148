import {OtherRunningTimeTypes, PlantEquipmentType} from '@hconnect/common/types'
import {
  Box,
  Typography,
  TableContainer,
  Table,
  TableBody,
  TableRow,
  TableCell,
  TableHead
} from '@mui/material'
import React, {FC} from 'react'
import {useTranslation} from 'react-i18next'
import {generatePath, useNavigate} from 'react-router-dom'

import {mergedList} from '../helpers/list.helpers'
import {useActualOrTotalValue} from '../hooks/useActualValue'
import {usePlantEquipmentData} from '../hooks/usePlantsEquipmentStatus'
import {useTranslationPrefix} from '../hooks/useTranslationPrefix'
import {PLANT_KPI_STATUS} from '../routing'
import {
  Equipment,
  EquipmentProductionVolumes,
  HierarchyNode,
  HierarchyNodeType,
  PlantEquipmentProductionVolumes,
  PlantEquipmentStatus,
  PxTrendMetaData
} from '../types'

import {NoDataReasonLabel, ProductionVolumeLabel} from './ProductionVolumeLabel'

type KilnStatusTableProps = {
  plants: HierarchyNode[]
}

enum StatusPageEquipmentType {
  Kiln = 'kiln',
  Mill = 'Mill'
}

function getProductionContent({
  type,
  plantEquipmentStatus,
  plantProductionVolumeData,
  pxTrendMetaData,
  flags
}: {
  type: StatusPageEquipmentType
  plantEquipmentStatus: PlantEquipmentStatus
  plantProductionVolumeData: PlantEquipmentProductionVolumes
  pxTrendMetaData?: PxTrendMetaData
  flags: {
    showActualValueOnly: boolean
    showActualOrTotalValue: boolean
    showTotalValueOnly: boolean
  }
}) {
  const {showActualValueOnly, showActualOrTotalValue, showTotalValueOnly} = flags

  /**
   * Note:
   * 1. Actual values and production volumes are picked from plantProductionVolumeData
   * 2. Running time is picked from plantEquipmentStatus
   */

  const actualValue =
    type === StatusPageEquipmentType.Mill
      ? plantProductionVolumeData?.actualCementProduction
      : plantProductionVolumeData?.actualClinkerProduction

  // (1) extract single actual value only for kiln / mill
  if (showActualValueOnly) {
    return actualValue || '-'
  }

  // (1) extract actual value for kiln / mill or fallback to total value
  if (showActualOrTotalValue && actualValue) {
    return actualValue
  }

  const equipmentList = mergedList<Equipment, EquipmentProductionVolumes>(
    'matchingId',
    plantEquipmentStatus?.equipments || [],
    plantProductionVolumeData?.equipmentProductionVolumes || []
  )

  // filter equipments for kiln / mill column
  const equipments =
    type === StatusPageEquipmentType.Mill
      ? equipmentList?.filter(
          (equipment) =>
            equipment.type === PlantEquipmentType.CEMENT_MILL ||
            equipment.type === PlantEquipmentType.RAW_MILL ||
            equipment.type === PlantEquipmentType.ROLLER_PRESS
        ) || []
      : equipmentList?.filter((equipment) => equipment.type === PlantEquipmentType.KILN) || []

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        flexWrap: 'wrap',
        rowGap: 0.5,
        columnGap: 3
      }}
    >
      {(showActualOrTotalValue || showTotalValueOnly) && equipments.length ? (
        equipments.map(({id, status, productionVolume, name}) => {
          return (
            // (1) show production value for every equipment
            // (2) show running time if volume is not present for equipment
            <ProductionVolumeLabel
              key={id}
              status={status || OtherRunningTimeTypes.UNKNOWN}
              volume={productionVolume}
              id={id}
              name={name}
            />
          )
        })
      ) : (
        <NoDataReasonLabel
          isMill={type === StatusPageEquipmentType.Mill}
          pxTrendMetaData={pxTrendMetaData}
        />
      )}
    </Box>
  )
}

export const KilnStatusTable: FC<KilnStatusTableProps> = ({plants}) => {
  const navigate = useNavigate()
  const {t} = useTranslation()
  const {performancePrefix} = useTranslationPrefix()

  const {plantEquipmentStatuses, plantEquipmentProductionVolumes} = usePlantEquipmentData()
  const {showActualValueOnly, showActualOrTotalValue, showTotalValueOnly} = useActualOrTotalValue()

  const allowedPlants = React.useMemo(() => plants?.filter((plant) => plant.isAllowed), [plants])

  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell align="left">{t(`${performancePrefix}.kilnStatus.label.plant`)}</TableCell>
            <TableCell align="left">{t(`${performancePrefix}.kilnStatus.label.kiln`)}</TableCell>
            <TableCell align="left">{t(`${performancePrefix}.kilnStatus.label.mills`)}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {allowedPlants?.map(
            ({id, name, type, pxTrendMetaData}, index) =>
              type === HierarchyNodeType.Plant && (
                <TableRow
                  key={id}
                  data-test-id={`kiln-status-table-row-${id}`}
                  onClick={() => navigate(generatePath(PLANT_KPI_STATUS, {plantId: id}))}
                  sx={{
                    cursor: 'pointer'
                  }}
                >
                  <TableCell
                    sx={{
                      paddingX: {xs: 0, sm: 2},
                      width: 150,
                      ...(allowedPlants.length - 1 === index
                        ? {borderBottom: 'none', paddingBottom: 0}
                        : {})
                    }}
                  >
                    <Typography variant="subtitle1">{name}</Typography>
                  </TableCell>
                  <TableCell
                    sx={{
                      ...(allowedPlants.length - 1 === index
                        ? {borderBottom: 'none', paddingBottom: 0}
                        : {})
                    }}
                  >
                    {getProductionContent({
                      type: StatusPageEquipmentType.Kiln,
                      plantEquipmentStatus: plantEquipmentStatuses[id],
                      plantProductionVolumeData: plantEquipmentProductionVolumes[id],
                      pxTrendMetaData,
                      flags: {showActualValueOnly, showActualOrTotalValue, showTotalValueOnly}
                    })}
                  </TableCell>
                  <TableCell
                    sx={{
                      ...(allowedPlants.length - 1 === index
                        ? {borderBottom: 'none', paddingBottom: 0}
                        : {})
                    }}
                  >
                    {getProductionContent({
                      type: StatusPageEquipmentType.Mill,
                      plantEquipmentStatus: plantEquipmentStatuses[id],
                      plantProductionVolumeData: plantEquipmentProductionVolumes[id],
                      pxTrendMetaData,
                      flags: {showActualValueOnly, showActualOrTotalValue, showTotalValueOnly}
                    })}
                  </TableCell>
                </TableRow>
              )
          )}
        </TableBody>
      </Table>
    </TableContainer>
  )
}
