import {FlexPage} from '@hconnect/common/components/FlexPage'
import {useWidth} from '@hconnect/common/hooks/useWidth'
import {NotificationProvider} from '@hconnect/uikit'
import {useTranslation} from 'react-i18next'

import {Shell} from '../../shared/components/Shell'
import {Context} from '../../shared/types/temp.types'
import {EventsTableLayout} from '../layouts/EventsTableLayout'

type Props = {
  context: Context
}

export const MyTasks: React.FC<Props> = ({context}) => {
  const {t} = useTranslation()
  const breakPoint = useWidth()
  const isSmall = breakPoint === 'xs' || breakPoint === 'sm'

  return (
    <NotificationProvider anchorOrigin={{horizontal: 'left', vertical: 'bottom'}}>
      <Shell context={context} enableNotifications={true}>
        <FlexPage useViewportHeight={!isSmall} title={t('myTasks.pageTitle')}>
          <EventsTableLayout translationPrefix="myTasks" />
        </FlexPage>
      </Shell>
    </NotificationProvider>
  )
}
