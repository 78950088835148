import {AxiosError, AxiosInstance} from 'axios'
import {useCallback, useMemo} from 'react'
import {useQueries, UseQueryResult} from 'react-query'
import {useNavigate} from 'react-router-dom'

import {handleError} from '../../../shared/helpers/errorHandling'
import {useAxios} from '../../../shared/hooks/useApi'
import {mapKilnCoefficientsDto} from '../../mappers'
import {
  KilnCoefficientsResponse,
  KpiDataPayload,
  KpiPfyPayload,
  PlannedYearlyKilnCoefficientHierarchy
} from '../../types'

import {KpiValue} from './commonKpiApiTypes'
import {getKilnCoefficientQueryKey, getKilnCoefficients} from './useKilnCoefficients'

export const getKilnCoefficientsPfy = async (
  axiosInstance: AxiosInstance,
  payload?: KpiPfyPayload
) => {
  const url = '/kpi/dashboard/kiln-coefficients-pfy'
  const response = await axiosInstance.get<PlannedYearlyKilnCoefficientHierarchy>(url, {
    params: {...payload}
  })

  return response.data
}

export const useKilnCoefficientsData = (payload: KpiDataPayload, enabled = true): KpiValue => {
  const axiosInstance = useAxios()
  const navigate = useNavigate()

  const QueryKey = getKilnCoefficientQueryKey(payload.showYTM)
  const queries = useQueries<
    [
      UseQueryResult<PlannedYearlyKilnCoefficientHierarchy, AxiosError>,
      UseQueryResult<KilnCoefficientsResponse, AxiosError>
    ]
  >([
    {
      queryKey: ['kiln-coefficients-pfy', {locationId: payload?.locationId, year: payload?.year}],
      queryFn: () =>
        getKilnCoefficientsPfy(axiosInstance, {
          locationId: payload?.locationId,
          year: payload?.year
        }),
      enabled,
      onError: (err: AxiosError) => handleError(err, navigate)
    },
    {
      queryKey: [
        QueryKey,
        {
          locationId: payload?.locationId,
          timeRange: payload.timeRange,
          showYTM: payload.showYTM
        }
      ],
      queryFn: () =>
        getKilnCoefficients(axiosInstance, {
          locationId: payload?.locationId,
          timeRange: payload.timeRange,
          showYTM: payload.showYTM
        }),
      enabled,
      onError: (err: AxiosError) => handleError(err, navigate)
    }
  ])

  const refetch = useCallback(() => {
    queries.forEach(({error, refetch: queryRefetch}) => {
      if (error) {
        void queryRefetch()
      }
    })
  }, [queries])

  const [pfyQuery, dataQuery] = queries

  const kilnCoefficients = useMemo(() => {
    const pfyData = pfyQuery.data
    const data = dataQuery.data
    return pfyData && data ? mapKilnCoefficientsDto(data, pfyData) : undefined
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(pfyQuery.data), JSON.stringify(dataQuery.data)])

  return {
    data: kilnCoefficients,
    isLoading: pfyQuery.isLoading || dataQuery.isLoading,
    error: pfyQuery.error || dataQuery.error,
    refetch
  }
}
